import $axios from '../utils';

export function getAllAdversaries(full = true, offset = 0, limit = 10000) {
    return $axios.get(`cti/adversary/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getAdversaryBy(key, value, full, offset, limit) {
    return $axios.get(`cti/adversary/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneAdversary(id, full) {
    return $axios.get(`cti/adversary/${id}?full=${full}`)
        .then(response => response.data)
}
export function createAdversary(data) {
    return $axios.post('cti/adversary/', data)
        .then(response => response.data)
}
export function updateAdversary(id, data) {
    return $axios.put(`cti/adversary/${id}`, data)
        .then(response => response.data)
}
export function deleteAllAdversary() {
    return $axios.delete('cti/adversary/')
        .then(response => response.data)
}
export function deleteAdversary(id) {
    return $axios.delete(`cti/adversary/${id}`)
        .then(response => response.data)
}
