<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="mitre-subtechnique">
        <div class="subtechnique-div">
            <div class="row p-0 m-0">
                <div class="col-11 pb-0" style="padding-top: 6px;">
                    <span class="ml-1 subtechnique-name" @click="openRoute(subtechnique.url)">{{ subtechnique.name }}</span>
                </div>
                <div class="col-1 pb-0">
                    <span class="more-button" @click="desc_open = !desc_open">{{ !desc_open ? $t('more') + '...' : $t('less') + '...'}}</span>
                </div>
            </div>
            <div class="row desc-row m-0" style="width: 100%;" v-if="desc_open">
                <p class="tactic-description" @click="desc_open = !desc_open">{{ subtechnique.description }}</p>
            </div>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script scoped>

export default {
    name: "Technique",
    data() {
        return {
            open: true,
            desc_open: false
        };
    },
    methods: {
        wrapString(str, maxLen) {
            if (str.length > maxLen) {
                return str.substring(0, maxLen) + "...";
            }
            return str;
        },
        openRoute(url) {
            // Open url on new window
            window.open(url, '_blank');
        }
    },
    props: {
        subtechnique: Object
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.more-button{
    color: #757575;
    font-size: small;
}
.subtechnique-div{
    font-size: 1rem;
}
.more-button:hover {
    cursor: pointer;
    text-decoration: underline;
}
.tactic-description {
    font-size: small;
    color: #757575;
    font-weight: 300;
    text-align: justify;
    margin-bottom: 1rem;
    padding-right: 2.5rem;
}
.mitre-technique {
    width: 100%;
    padding: 0;
}
.name-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}

.tactic-description {
    font-size: small;
    color: #757575;
    font-weight: 300;
    text-align: justify;
    margin-bottom: 1rem;
    padding-right: 2.5rem;
}
.mitre-subtechnique{
    padding: 0;
}
</style>