<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="mitre-technique">
        <p class="technique-name" @click="open = !open">
            <span class="alerts-in-company-technique critical" v-if="technique.alerts > 15">{{ technique.alerts }}</span>
            <span class="alerts-in-company-technique high" v-else-if="technique.alerts > 10">{{ technique.alerts }}</span>
            <span class="alerts-in-company-technique medium" v-else-if="technique.alerts > 5">{{ technique.alerts }}</span>
            <span class="alerts-in-company-technique low" v-else-if="technique.alerts > 2">{{ technique.alerts }}</span>
            <span class="alerts-in-company-technique info" v-else-if="technique.alerts > 0">{{ technique.alerts }}</span>

            <span class="name-hover" v-tooltip.top="technique.name" @click="openRoute(technique.url)">{{ wrapString(technique.name, 22)}}</span>

            <span v-if="technique.subtechniques.length > 0" class="technique-open m-0"> <v-icon :name="open ? 'bi-chevron-up' : 'bi-chevron-down'"  scale="0.8"></v-icon></span>

        <p class="technique-count" v-if="technique.subtechniques.length > 0">
            {{ technique.subtechniques.length + ' ' + $t('subtechniques') }}
        </p>
        </p>
        <div class="mitre-subtechnique" v-if="technique.subtechniques && open"
            v-for="subtechnique in technique.subtechniques" :key="subtechnique.id">
            <p class="subtechnique-name">
                <span class="alerts-in-company-technique critical" v-if="subtechnique.alerts > 15">{{ subtechnique.alerts }}</span>
                <span class="alerts-in-company-technique high" v-else-if="subtechnique.alerts > 10">{{ subtechnique.alerts }}</span>
                <span class="alerts-in-company-technique medium" v-else-if="subtechnique.alerts > 5">{{ subtechnique.alerts }}</span>
                <span class="alerts-in-company-technique low" v-else-if="subtechnique.alerts > 2">{{ subtechnique.alerts }}</span>
                <span class="alerts-in-company-technique info" v-else-if="subtechnique.alerts > 0">{{ subtechnique.alerts }}</span>

                <span class="name-hover" @click="openRoute(subtechnique.url)">{{ wrapString(subtechnique.name, 60)}}</span>
            </p>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>

export default {
    name: "Technique",
    data() {
        return {
            open: false
        };
    },
    methods: {
        wrapString(str, maxLen) {
            if (str.length > maxLen) {
                return str.substring(0, maxLen) + "...";
            }
            return str;
        },
        openRoute(url) {
            // Open url on new window
            window.open(url, '_blank');
        }
    },
    props: {
        technique: Object
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.technique-name {
    padding: 0.5rem .5rem;
    font-size: small;
    background-color: #f1f1f1;
    color: #222222;
    border-bottom: 1px solid #222222;
    border-right: 1px solid #bbbbbb;
    border-left: 1px solid #bbbbbb;
    border-top: 1px solid #bbbbbb;
}

.active-technique {
    color: red;
}

.name-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}

.mitre-subtechnique {
    padding: 0.25rem .5rem;
    font-size: small;
    background-color: #ffffff;
    color: #222222;
    border-bottom: 1px solid #222222;
    border-right: 1px solid #bbbbbb;
    border-left: 1px solid #bbbbbb;
    margin-left: 1rem;
}

.subtechnique-name:hover {
    cursor: pointer;
    text-decoration: underline;
}
.alerts-in-company-technique{
    border-radius: 50%;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    text-align: center;
    margin-right: 0.25rem;
}
</style>