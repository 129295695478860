import $axios from '../utils';

export function getAllCampaigns(full = true, offset = 0, limit = 10000) {
    return $axios.get(`cti/campaign/?full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getCampaignBy(key, value, full, offset, limit) {
    return $axios.get(`cti/campaign/by?key${key}&value=${value}&full=${full}&offset=${offset}&limit=${limit}`)
        .then(response => response.data)
}
export function getOneCampaign(id, full) {
    return $axios.get(`cti/campaign/${id}?full=${full}`)
        .then(response => response.data)
}
export function createCampaign(data) {
    return $axios.post('cti/campaign/', data)
        .then(response => response.data)
}
export function updateCampaign(id, data) {
    return $axios.put(`cti/campaign/${id}`, data)
        .then(response => response.data)
}
export function deleteAllCampaign() {
    return $axios.delete('cti/campaign/')
        .then(response => response.data)
}
export function deleteCampaign(id) {
    return $axios.delete(`cti/campaign/${id}`)
        .then(response => response.data)
}
