import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import CampaignCard from "../../components/Partials/CampaignCard/CampaignCard.vue";
import * as $api from "../../api/api";

import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Campaigns",
    /* Data */
    setup() {

    },
    data() {
        return {
            loading: false,
            user: {
                "permissions": []
            },
            campaigns: [
                /*
                {
                id: 1,
                name: "Campaign 1",
                description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                version: "1.0.0",
                first_seen: "2021-09-01T00:00:00",
                last_seen: "2021-09-01T00:00:00",
                status: "Active",
                threat_level: "High",
                intel_reports: 5,
            }
                */
            ],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog,
        CampaignCard
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
    },
    async mounted() {
        this.loading = true;
        await $api.getAllCampaigns().then((response) => {
            this.campaigns = response;
            this.loading = false;
        }).catch((err) => {
            console.log(`[-] Error (getAllCampaigns): ${err}`);
        });
        
    }
};
export default MyShared;