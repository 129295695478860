<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="adversary-card row">
        <div class="col-2 adversary-left-col">
            <img class="nav-bar-logo" v-if="adversary.picture" v-bind:src="getAdversarieLogo(adversary.picture)" alt="logo" />
            <img class="nav-bar-logo" v-else v-bind:src="adversaryTempImg" alt="logo" />
            <p class="adversary-img-caption">{{ $t('seenInYourCompany') }}</p>
            <div class="row text-center pl-2 pr-2">
                <div class="col-4 adversary-resume-icon">
                    <p v-tooltip.top="$t('seen_in_general')"><v-icon name="md-warning" scale="1.25" fill="#939393"></v-icon></p>
                    <p>{{7}}</p>
                </div>
                <div class="col-4 adversary-resume-icon">
                    <p v-tooltip.top="$t('seen_on_incidents')"><v-icon name="md-cases-round" scale="1.25" fill="#939393"></v-icon></p>
                    <p>{{3}}</p>
                </div>
                <div class="col-4 adversary-resume-icon">
                    <p v-tooltip.top="$t('seen_on_reports')"><v-icon name="md-description" scale="1.25" fill="#939393"></v-icon></p>
                    <p>{{4}}</p>
                </div>
            </div>
        </div>
        <div class="col-10 adversary-right-col pl-3">
            <h6 class="adversary-name" >{{adversary.name}} <span v-tooltip.top="$t('open_in_mitre')"><v-icon name="bi-box-arrow-up-right" scale="1" @click="openInMitre(adversary.mitre_url)" style="margin-bottom: 4px;cursor: pointer;"></v-icon></span></h6>
            <p class="adversary-description">{{wrapString(removeMarkdown(adversary.description),450)}}</p>
            <div class="row adversary-data w-100">
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('alias')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{wrapString(prettifyArray(adversary.alias),40)}}</p>
                    <p class="adversary-data-title">{{$t('version')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{adversary.version}}</p>
                    <!--
                    <p class="adversary-data-title">{{$t('type')}}</p>
                    <p class="adversary-data-value">{{adversary.type}}</p>
                    -->
                </div>
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('first_seen')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{formatDate(adversary.first_seen)}}</p>
                    <p class="adversary-data-title">{{$t('last_seen')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{formatDate(adversary.last_seen)}}</p>
                </div>
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('contributors')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{wrapString(prettifyArray(adversary.contributors),60)}}</p>
                    <!-- 
                    <p class="adversary-data-title">{{$t('motivation')}}</p>
                    <p class="adversary-data-value">{{adversary.motivation}}</p>
                    <p class="adversary-data-title">{{$t('origin')}}</p>
                    <p class="adversary-data-value">{{adversary.region}}</p>
                    
                    <p class="adversary-data-title">{{$t('status')}}</p>
                    <p class="adversary-data-value">{{adversary.status}}</p>
                    -->
                </div>
                
            </div>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
export default {
    name: "AdversaryCard",
    data() {
        return {
            adversaryTempImg:  require('@/assets/user-default-img.png'),
        }
    },
    components: {
    },
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        getAdversarieLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        prettifyArray(array){
            if (array === null || array === undefined){
                return '';
            }
            return array.join(', ');
        },
        openInMitre(url){
            window.open(url, '_blank');
        },
        wrapString(string, max_char){
            if (string === null || string === undefined){
                return '';
            }
            if (string.length > max_char){
                return string.substring(0, max_char) + '...';
            }
            return string;
        },
        removeMarkdown(string){
            if (string === null || string === undefined)
                return '';
            // Find all url between () and remove them
            let a =  string.replace(/\(.*?\)/g, '');
            // Find [ remove them
            let b = a.replace(/\[/g, '');
            // Find ] remove them
            b = b.replace(/\]/g, '');
        
            return b;
        }
    },
    props: {
        adversary: Object,
    },
    mounted(){
        
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.adversary-card {
    padding: 1rem;
    margin: 0.5rem;
    width: 48rem;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    padding-bottom: 1.5rem;
    height: 340px;
}
.adversary-left-col {
    padding: 0;
    margin: 0;
    text-align: center;
}
.adversary-data{
    padding-bottom: 0.25rem;
}

.adversary-right-col {
    padding: 0;
    margin: 0;
}
.adversary-left-col img {
    width: 6rem;
    height: 6rem;
    object-fit: cover;
}
.adversary-resume-icon{
    padding: 0rem;
    text-align: center;
}
.adversary-img-caption{
    font-size: 0.75rem;
    margin: 0.5rem;
    color: #999ea3;
}
.adversary-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
}
.adversary-description {
    font-size: .9rem !important;
    margin: 0;
}
.adversary-data-title{
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0;
    margin-top: 0.5rem;
    color: #999ea3;
}
</style>