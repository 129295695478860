<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <div class="info-card" v-if="!close">
        <div class="col-12 p-0 m-0" style="width:100%">
          <i class="pi pi-times info-card-icon-close "
            style="float: right; cursor: pointer; z-index: 999; position:relative;" @click="close = !close"></i>
        </div>
        <div class="row">
          <i class="pi pi-info-circle info-card-icon mr-2 pl-1" style="position:absolute;"></i>
          <p class="pt-0 mt-0 mb-0" style="padding-left: 3.25em"><strong>{{ $t('incidentInfoTitle') }}</strong></p>
          <p class="pt-0 mt-0" style="padding-left: 3.25em">{{ $t('incidentInfoDescription') }}</p>
        </div>
      </div>
      <div class="row details-asset-title mr-0" v-if="loading_details">
        <ContentLoader height="60px"></ContentLoader>
      </div>
      <div class="row details-asset-title mr-0" v-else>
        <h3 v-if="details.severity == 0">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#aeb5c1"></i>
        </h3>
        <h3 v-if="details.severity == 1">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#6dc193"></i>
        </h3>
        <h3 v-if="details.severity == 2">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#f7b34c"></i>
        </h3>
        <h3 v-if="details.severity == 3">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#ef6154"></i>
        </h3>
        <h3 v-if="details.severity == 4">
          {{ details.title }}&nbsp;<i class="pi pi-circle-fill" style="color:#b83c32"></i>
        </h3>
        <p class="mb-0">
          <span class="id-prefix" style="text-decoration:none;"></span>
          <span class="details-id" style="text-decoration:none; cursor:default;">
            {{ details.id }}
          </span>
        </p>
      </div>
      <br>
      <div class="row" style="margin-left: 0px;">
        <div class="col-4 main-details-info p-0">
          <div class="scroll" style="height:auto; overflow-y:hidden; overflow-x:hidden;">
            <div class="sub-scroll p-2">
              <div class="row details-data pl-2 pr-2">
                <div class="col-4 p-2" v-if="loading_details">
                  <ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader>
                </div>
                <div class="col-4 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="type-dropdown">
                      <template #content>
                        <i class="fa-solid fa-temperature-empty subdetail-icon">
                          <p class="subdetail-name">&nbsp;{{
                            prettifyString(details.status[details.status.length - 1].status) }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="status in statuses" :key="status" @click="addNewStatus(status)">
                        {{ status.label }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-4 p-2" v-if="loading_details">
                  <ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader>
                </div>
                <div class="col-4 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="criticality-dropdown">
                      <template #content>
                        <i v-if="details.severity == 0" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#aeb5c1">
                          <p class="subdetail-name" v-if="details.severity == 0">&nbsp;{{ $t('informational') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#6dc193">
                          <p class="subdetail-name" v-if="details.severity == 1">&nbsp;{{ $t('low') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#f7b34c">
                          <p class="subdetail-name" v-if="details.severity == 2">&nbsp;{{ $t('medium') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#ef6154">
                          <p class="subdetail-name" v-if="details.severity == 3">&nbsp;{{ $t('high') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                        <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon"
                          style="color:#b83c32">
                          <p class="subdetail-name" v-if="details.severity == 4">&nbsp;{{ $t('critical') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="severity in criticalities" :key="severity"
                        @click="details.severity = severity.value">
                        {{ severity.label }}
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-4 p-2" v-if="loading_details">
                  <ContentLoader class="pl-4" height="40px" width="95%"></ContentLoader>
                </div>
                <div class="col-4 p-1" v-else>
                  <div :class="!locked ? 'dropdown' : ''">
                    <Card :class="!locked ? 'dropdown-toggle' : 'disabled'" :data-toggle="!locked ? 'dropdown' : ''"
                      style="display: -webkit-box;" id="team-dropdown" data-toggle="dropdown">
                      <template #content>
                        <i class="fa-solid fa-user subdetail-icon">
                          <p class="subdetail-name" v-if="details.team != null">
                            &nbsp;{{ details.team.name }}</p>
                          <p class="subdetail-name" v-else>&nbsp;{{ $t('undefined') }}</p>
                          <p class="subdetail-tag">&nbsp;{{ $t('team') }}</p>
                        </i>
                      </template>
                    </Card>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" v-for="team in teams" :key="team" @click="updateTeam(details.id, team)">
                        {{ team.name }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row details-data mt-2" v-if="loading_details">
                <ContentLoader height="25em"></ContentLoader>
              </div>
              <div class="row details-data mt-2 pl-2 pr-2" v-else style="padding-bottom:4em;">
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('statusDescription') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p v-if="locked || !status_has_changed" class="mb-1">{{
                      details.status[details.status.length - 1].description }}</p>
                    <Textarea v-if="!locked && status_has_changed"
                      v-model="details.status[details.status.length - 1].description" :disabled="locked"
                      style="width:100%;"></Textarea>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('description') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p v-if="locked" class="mb-1">{{ details.description }}</p>
                    <Textarea v-else v-model="details.description" :disabled="locked" style="width:100%;"></Textarea>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-1 main-details-title"><strong>{{ $t('createdAt') }}</strong></p>
                  <div class="col-12 pt-0 pb-0">
                    <p class="mb-1">{{ formatDate(details.created_at) }}</p>
                  </div>
                </div>
                <div class="row pr-0">
                  <p class="mb-0 main-details-title"><strong>{{ $t('correlationRule') }}</strong></p>
                  <div class="row ml-0 mt-2">
                    <div class="detection-card">
                      <p class="mt-0 mb-0"><strong>{{ details.correlation_rule.title }}</strong></p>
                      <p class="details-id mt-0 mb-0" style="font-size: 0.8em;"
                        @click="$router.push({ path: 'detection-rule-details', query: { id: details.correlation_rule.id } })">
                        {{ details.correlation_rule.id }}
                      </p>
                      <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.correlation_rule.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pt-2 details-actions-div">
            <Button v-if="!locked" class="btn-custom-save mr-2" :label="$t('save')" @click="onSaveUpdate();"></Button>
            <Button v-if="locked" class="btn-custom-save mr-2" :label="$t('edit')" @click="unlockForm()"></Button>
            <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('cancel')"
              @click="onCancelUpdate();"></Button>
            <Button v-if="!locked" class="btn-custom-cancel mr-2" :label="$t('delete')" @click="lockForm()"
              style="float:right;" icon="pi pi-trash"></Button>
          </div>
        </div>

        <div class="col-8 pt-0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <div class="sec-details-info">
              <TabView v-model:activeIndex="active_tab">
                <!-- ====================================================================== Events =========================================================================== -->
                <TabPanel :header="$t('events')">
                  <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y: auto; overflow-x:hidden;">
                    <div class="aux p-0">
                      <div class="row mt-0" v-if="!no_incidents">
                        <div class="col-3 text-center" v-if="loading_details">
                          <ContentLoader></ContentLoader>
                        </div>
                        <div class="col-3 pt-0 pb-0" v-else style="height:15em;">
                          <Chart type="doughnut" :data="pieData1" :options="pieOptions" />
                        </div>
                        <div class="col-3 text-center" v-if="loading_details">
                          <ContentLoader></ContentLoader>
                        </div>
                        <div class="col-3 pt-0 pb-0" v-else style="height:15em;;">
                          <Chart type="doughnut" :data="pieData2" :options="pieOptions" />
                        </div>
                        <div class="col-6 text-center" v-if="loading_details">
                          <ContentLoader></ContentLoader>
                        </div>
                        <div class="col-6 pt-0 pb-0" v-else style="height:auto;">
                          <Timeline :data="events_timeline" :title="$t('eventsInTime')" router_type="event"></Timeline>
                        </div>
                      </div>
                      <div class="col-12 pl-0 pr-0 text-center" v-if="loading_details">
                        <ContentLoader></ContentLoader>
                      </div>
                      <div class="row" style="width: 100%;" v-else>
                        <div class="col-12 mb-3 text-center">
                          <h6><i class="pi pi-times-circle"></i>&nbsp;{{ $t('noDataFound') }}</h6>
                        </div>
                      </div>
                      <DataTable v-else :value="details.events" :paginator="true" class="p-datatable-customers mt-0"
                        :rows="9" :row-hover="true" dataKey="id" v-model:filters="filters" filter-display="menu"
                        :loading="loading_details" :selectionMode="(!editMode) ? 'single' : ''"
                        paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                        :rows-per-page-options="[10, 25, 50]"
                        current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
                        :global-filter-fields="['id', 'name', 'os', 'host', 'type', 'criticality', 'last_act_date', 'risk']"
                        responsive-layout="scroll" @row-click="setSelectedData($event.data)">
                        <template #empty>
                          {{ $t('noDataFound') }}
                        </template>
                        <template #loading>
                          {{ $t('loadingData') }}
                        </template>
                        <Column field="title" :header="$t('title')" sortable>
                          <template #body="{ data }">
                            <span @click="$router.push({ path: 'event-details', query: { id: data.id } });"
                              class="row-name-link">{{ formatString(data.title) }}</span>
                          </template>
                        </Column>
                        <Column field="severity" :header="$t('severity')" sortable>
                          <template #body="{ data }">
                            <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                            <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                            <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                            <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                            <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                          </template>
                        </Column>
                        <Column field="detection_rule_id" :header="$t('detectionRule')" sortable>
                          <template #body="{ data }">
                            <span v-tooltip.top="data.detection_rule.title">
                              {{ formatString(data.detection_rule.title) }}
                            </span>
                          </template>
                        </Column>
                        <Column field="created_at" :header="$t('createdAt')" sortable>
                          <template #body="{ data }">
                            {{ formatDate(data.created_at) }}
                          </template>
                        </Column>
                        <Column field="status" :header="$t('status')" sortable>
                          <template #body="{ data }">
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'New'"
                              style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">New</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Mitigated'"
                              style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Mitigated</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Partial mitigated'"
                              style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Partial
                              Mitigated</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Active'"
                              style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Active</span>
                            <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'Closed'"
                              style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Closed</span>
                          </template>
                        </Column>
                      </DataTable>
                    </div>
                  </div>
                </TabPanel>
                <!-- ====================================================================== Status history =========================================================================== -->
                <TabPanel :header="$t('statusHistory')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <Timeline :data="status_timeline" :title="$t('')" router_type="status" v-if="!loading_details">
                  </Timeline>
                  <ScrollPanel style="width: 100%; height:25em; margin-top:-4em;" class="custom" v-if="!loading_details">
                    <div class="row pb-2 pl-3 pr-3 pt-1" style="overflow-y:auto;">
                      <div class="aux p-0" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(status, index) in details.status.slice().reverse()" :key="status"
                          :groupName="status.status + ' - ' + status.date" :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p v-if="prettifyString(status.status) == 'New'"
                              style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              New</p>
                            <p v-if="prettifyString(status.status) == 'Mitigated'"
                              style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Mitigated</p>
                            <p v-if="prettifyString(status.status) == 'Partial mitigated'"
                              style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Partial Mitigated</p>
                            <p v-if="prettifyString(status.status) == 'Active'"
                              style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Active</p>
                            <p v-if="prettifyString(status.status) == 'Closed'"
                              style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">
                              Closed</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.date }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Investigation =========================================================================== -->
                <TabPanel :header="$t('investigate')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <div class="investigate-canvas dashboard-canvas row p-0 m-0" v-if="!loading_details"
                    style="width: 100%;">
                    <VueFlow :class="'col-12 p-0 m-0'" v-model="flow_chart" :zoom-on-scroll="true" :zoom-on-pinch="false"
                      :zoom-on-double-click="false" :pan-on-scroll-speed="0" :pan-on-drag="true" :prevent-scrolling="true"
                      :default-zoom="defaultZoom" :max-zoom="4" :min-zoom="0.1" connection-mode="loose"
                      @node-click="onNodeClick($event)" @node-drag-start="onNodeDragStart($event)"
                      @node-drag="onNodeDrag($event)" @node-drag-stop="onNodeDragStop($event)"
                      @node-contextmenu="onNodeContextMenu($event)" @node-double-click="onNodeDoubleClick($event)"
                      @edge-update="onEdgeUpdate({ edge, connection })" @connect="onConnect(); onConnectCustom($event)"
                      @edge-update-start="onEdgeUpdateStart(edge)" @edge-update-end="onEdgeUpdateEnd(edge)"
                      @edge-double-click="onEdgeDoubleClick($event)" @edge-click="onEdgeClick($event)">

                      <Controls :fitViewParams="{ maxZoom: 2, offset: { x: -300, y: 0 } }" />
                      <MiniMap pannable zoomable height="100" width="100" />
                      <!--
                     
                      @node-mouse-enter="onNodeMouseEnter($event)"
                      @node-mouse-leave="onNodeMouseLeave($event)"
                      @node-mouse-move="onNodeMouseMove($event)"
                      -->

                      <!-- Incident node -->
                      <template #node-incident="props">
                        <div
                          :class="(props.data.selected) ? 'card incident-node selected-node node-default-style' : 'card incident-node node-default-style'"
                          :style="'color: ' + props.data.icon_color + ';'" v-tooltip.top="props.label">
                          <div class="warning-node-div" v-if="props.data.warning">
                            <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                          </div>
                          <div class="card-body text-center pt-0 pb-1 pl-1 pr-1">
                            <i class="fa pi-briefcase pi fa-lg"></i>
                          </div>
                        </div>
                        <Handle type="target" :position="Position.Top" :id="'top-handle'" v-if="props.data.handles[0]" />
                        <Handle type="target" :position="Position.Right" :id="'right-handle'"
                          v-if="props.data.handles[1]" />
                        <Handle type="target" :position="Position.Bottom" :id="'bottom-handle'"
                          v-if="props.data.handles[2]" />
                        <Handle type="target" :position="Position.Left" :id="'left-handle'"
                          v-if="props.data.handles[3]" />
                        <div class="my-node-node-title text-center">{{ formatString(props.label, 18) }}</div>
                      </template>

                      <!-- Event node -->
                      <template #node-event="props">
                        <div
                          :class="(props.data.selected) ? 'card event-node selected-node node-default-style' : 'card event-node node-default-style'"
                          :style="'color: ' + props.data.icon_color + ';'" v-tooltip.top="props.label">
                          <div class="card-body text-center pt-0 pb-1 pl-1 pr-1">
                            <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                          </div>
                          <div class="warning-node-div" v-if="props.data.warning">
                            <i class="fa pi-exclamation-triangle pi fa-lg"></i>
                          </div>
                        </div>
                        <Handle type="target" :position="Position.Top" :id="'top-handle'" v-if="props.data.handles[0]" />
                        <Handle type="target" :position="Position.Right" :id="'right-handle'"
                          v-if="props.data.handles[1]" />
                        <Handle type="target" :position="Position.Bottom" :id="'bottom-handle'"
                          v-if="props.data.handles[2]" />
                        <Handle type="target" :position="Position.Left" :id="'left-handle'"
                          v-if="props.data.handles[3]" />
                        <div class="my-node-node-title text-center">{{ formatString(props.label, 18) }}</div>
                      </template>

                    </VueFlow>

                    <div class="aux-canvas-div col-5" style="pointer-events:none;">
                      <ScrollPanel style="height:60vh; overflow-y:auto;">
                        <!-- ========================= Node details ========================= -->
                        <Transition name="">
                          <div class="row" v-if="!isNodeDetails" @click="isNodeDetails = true; focusSelectedNode()">
                            <div class="col-10">
                            </div>
                            <div :class="'col-2 card side-slide-fade-enter-active mt-1 collapsed-investigate'"
                              style="pointer-events:all;">
                              <i class="pi pi-angle-left close-investigate-window-icon"><i
                                  class="pi pi-info-circle"></i></i>
                            </div>
                          </div>
                        </Transition>
                        <Transition name="side-slide-fade">
                          <div :class="'investigate-details col-12 card side-slide-fade-enter-active mt-1'"
                            style="pointer-events:all;" v-if="isNodeDetails">
                            <i class="pi pi-angle-right close-investigate-window-icon"
                              @click="isNodeDetails = false; unfocusSelectedNode()"></i>
                              
                            <div class="investigate-details-title">
                              <h6 style="font-weight: bold; color: lightgray;">{{ selectedNode.data.type.toUpperCase() }}</h6>
                              <h5 v-if="selectedNode.data.object.severity == 0" class="mb-0">
                                <i class="pi pi-circle-fill" style="color:#aeb5c1"></i>&nbsp;{{
                                  selectedNode.data.object.title }}
                              </h5>
                              <h5 v-if="selectedNode.data.object.severity == 1" class="mb-0">
                                <i class="pi pi-circle-fill" style="color:#6dc193"></i>&nbsp;{{
                                  selectedNode.data.object.title }}
                              </h5>
                              <h5 v-if="selectedNode.data.object.severity == 2" class="mb-0">
                                <i class="pi pi-circle-fill" style="color:#f7b34c"></i>&nbsp;{{
                                  selectedNode.data.object.title }}
                              </h5>
                              <h5 v-if="selectedNode.data.object.severity == 3" class="mb-0">
                                <i class="pi pi-circle-fill" style="color:#ef6154"></i>&nbsp;{{
                                  selectedNode.data.object.title }}
                              </h5>
                              <h5 v-if="selectedNode.data.object.severity == 4" class="mb-0">
                                <i class="pi pi-circle-fill" style="color:#b83c32"></i>&nbsp;{{
                                  selectedNode.data.object.title }}
                              </h5>
                            </div>
                            <div class="investigate-details-description">
                              {{ selectedNode.data.object.description }}
                            </div>
                            <div class="investigate-details-category">
                              <p class="category-title">{{ $t('ID') + ': ' }}</p>
                              <p class="category-value details-id"
                                @click="$router.push({ path: selectedNodeType + '-details', query: { id: selectedNode.data.object.id } })">
                                {{
                                  selectedNode.data.object.id }}</p>
                            </div>
                            <div class="investigate-details-category" v-if="selectedNode.data.object.created_at">
                              <p class="category-title">{{ $t('createdAt') + ': ' }}</p>
                              <p class="category-value">{{ formatDate(selectedNode.data.object.created_at) }}</p>
                            </div>
                            <div class="investigate-details-category" v-if="selectedNode.data.object.status">
                              <p class="category-title">{{ $t('status') + ': ' }}</p>
                              <p class="category-value status-label"
                                v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'New'"
                                style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                                New</p>
                              <p class="category-value status-label"
                                v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Mitigated'"
                                style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                                Mitigated</p>
                              <p class="category-value status-label"
                                v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Partial mitigated'"
                                style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                                Partial Mitigated</p>
                              <p class="category-value status-label"
                                v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Active'"
                                style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                                Active</p>
                              <p class="category-value status-label"
                                v-if="prettifyString(selectedNode.data.object.status[selectedNode.data.object.status.length - 1].status) == 'Closed'"
                                style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">
                                Closed</p>
                            </div>

                            <!-- ------------------------ Node options ------------------------ -->
                            <div class="node-options">
                              <p class="node-options-title" @click="openOptions = !openOptions" style="cursor:pointer;"><i
                                  class="pi pi-cog" style="font-size:0.9em"></i> {{
                                    $t('nodeOptions') }}<i class="pi pi-angle-down" v-if="!openOptions"
                                  style="float:right"></i><i class="pi pi-angle-up" v-if="openOptions"
                                  style="float:right"></i></p>
                              <hr class="mb-0 mt-0">
                              <section v-if="openOptions">
                                <p class="node-options-subtitle">General</p>
                                <div class="general-node-options m-0 p-0">
                                  <Button @click="deleteNode(selectedNode.id)"
                                    class="btn-custom-cancel node-options-button" icon="pi pi-trash"
                                    :label="$t('delete') + ' ' + $t('node')"></Button>
                                  <Button @click="exploreNode(selectedNode.id, selectedNodeType)"
                                    class="btn-custom-cancel node-options-button" icon="pi pi-eye"
                                    :label="$t('explore') + ' ' + $t('item')"></Button>
                                </div>

                                <div class="row p-0 m-0">
                                  <div class="col-6 p-0 m-0">
                                    <p class="node-options-subtitle">{{ $t('nodeType') }}</p>
                                    <Dropdown v-model="selectedNodeType" :options="nodeTypes"
                                      :placeholder="$t('nodeType')" @change="updatedNodeType(selectedNodeType)" />
                                  </div>
                                  <div class="col-6 p-0 m-0 node-colorpicker">
                                    <p class="node-options-subtitle">{{ $t('node') }} {{ $t('color') }}</p>
                                    <ColorPicker class format="hex" v-model="selectedNodeData.icon_color"
                                      @change="updateNodeColor(selectedNodeData.icon_color)" />
                                  </div>
                                </div>

                                <p class="node-options-subtitle">{{ $t('handles') }}</p>
                                <div class="general-node-options m-0 p-0 flex flex-wrap justify-content-center gap-3">
                                  <Checkbox v-model="selectedNodeData.handles[0]" inputId="Top" name="handles"
                                    :value="selectedNodeData.handles[0]" :binary="true" />
                                  <label for="Top" class="ml-2"> Top </label>
                                  <Checkbox v-model="selectedNodeData.handles[1]" inputId="Right" name="handles"
                                    :value="selectedNodeData.handles[1]" :binary="true" />
                                  <label for="Right" class="ml-2"> Right </label>
                                  <Checkbox v-model="selectedNodeData.handles[2]" inputId="Bottom" name="handles"
                                    :value="selectedNodeData.handles[2]" :binary="true" />
                                  <label for="Bottom" class="ml-2"> Bottom </label>
                                  <Checkbox v-model="selectedNodeData.handles[3]" inputId="Left" name="handles"
                                    :value="selectedNodeData.handles[3]" :binary="true" />
                                  <label for="Left" class="ml-2"> Left </label>
                                </div>

                                <p class="node-options-subtitle">{{ $t('others') }}</p>
                                <div class="general-node-options m-0 p-0 flex flex-wrap justify-content-center gap-3">
                                  <Checkbox v-model="selectedNodeData.warning" inputId="warning" name="handles"
                                    :value="selectedNodeData.warning" :binary="true" />
                                  <label for="warning" class="ml-2"> Add Warning </label>
                                  <textarea v-if="selectedNodeData.warning" v-model="selectedNodeData.warning_description"
                                    class="form-control" placeholder="Warning description" rows="3"></textarea>
                                </div>
                              </section>
                            </div>
                          </div>
                        </Transition>
                        <!--
                        <Transition name="">
                          <div class="row" v-if=" !isNewNode " @click="isNewNode = true;">
                            <div class="col-10">
                            </div>
                            <div :class=" 'col-2 card side-slide-fade-enter-active mt-1 collapsed-investigate' ">
                              <i class="pi pi-angle-left close-investigate-window-icon">
                                <p class="collapsed-investigate-icon"><i class="pi pi-stop"></i></p>
                              </i>
                            </div>
                          </div>
                        </Transition>
                        <Transition name="side-slide-fade">
                          <div :class=" 'investigate-new-node col-12 card side-slide-fade-enter-active mt-1' "
                            v-if=" isNewNode ">
                            <i class="pi pi-angle-right close-investigate-window-icon" @click="isNewNode = false;"></i>
                            <div class="general-node-options m-0 p-0">
                              <div class="investigate-details-title">
                                <p class="node-options-subtitle mt-0 mb-2">{{ $t('node') }} {{ $t('new') }}</p>
                              </div>
                              <Button @click="newNode('event')" class="btn-custom-cancel node-options-button"
                                icon="pi pi-plus" :label=" $t('new') "></Button>
                            </div>
                          </div>
                        </Transition>
                         -->
                        <!-- ========================= Edge options ========================= -->
                        <Transition name="">
                          <div class="row" v-if="!isEdgeOptions" @click="isEdgeOptions = true; focusSelectedEdge()">
                            <div class="col-10">
                            </div>
                            <div :class="'col-2 card side-slide-fade-enter-active mt-1 collapsed-investigate'"
                              style="pointer-events:all;">
                              <i class="pi pi-angle-left close-investigate-window-icon">
                                <p class="collapsed-investigate-icon"><i> <img
                                      :src="require('@/assets/investigation/edge.svg')"
                                      style="height:16px;margin-bottom: 6px;"></i></p>
                              </i>
                            </div>
                          </div>
                        </Transition>
                        <Transition name="side-slide-fade">
                          <div :class="'investigate-edge-options col-12 card side-slide-fade-enter-active mt-1'"
                            style="pointer-events:all;" v-if="isEdgeOptions">
                            <i class="pi pi-angle-right close-investigate-window-icon"
                              @click="isEdgeOptions = false; unfocusSelectedEdge()"></i>
                            <div class="general-node-options m-0 p-0">
                              <div class="investigate-details-category">
                                <p class="category-title">Edge options</p>
                              </div>
                              <p class="node-options-subtitle">{{ $t('handles') }} {{ $t('color') }}</p>
                              <ColorPicker class format="hex" v-model="edgeObject.style.stroke" @change="updateEdge()" />
                              <p class="node-options-subtitle">{{ $t('handles') }} {{ $t('label') }}</p>
                              <InputText v-model="edgeObject.label" @change="updateEdge()" />
                            </div>
                          </div>
                        </Transition>
                      </ScrollPanel>
                    </div>
                  </div>
                  <!-- ========================= Node options ========================= -->
                  <!--
                  <Button @click="newNode('event')" class="btn-custom-cancel node-options-button-new-node"
                    icon="pi pi-plus" :label="$t('add') + ' ' + $t('node')"
                    style="pointer-events:all; background-color:#fff !important;">
                  </Button>
                -->
                </TabPanel>
                <TabPanel :header="$t('mitreAttack')">
                  <div class="mitre-tab pl-2 pr-2 pt-1 pb-4" style="overflow-y:auto; height: 65vh">
                    <h6 class="mt-3 ml-0 mitre-title">{{ $t('tacticsAndTechniquesObserved') }}</h6>
                    <div class="mitre-matrix" v-if="!mitre_available"></div>
                    <div class="mitre-matrix" v-else>
                      <Tactic :tactic="tactic" v-for="tactic in mitre_matrix" :key="tactic.id"></Tactic>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
import Tactic from "../Partials/Mitre/Tactic.vue";
export default {
  mixins: [MyShared],
};
</script>

<script setup>
import { useMq } from "vue3-mq";
import { ref } from "vue";

import { Position, Handle, Panel, PanelPosition, VueFlow, isNode, useVueFlow, useEdge, MarkerType, Elements } from '@vue-flow/core'

const { onPaneReady, onNodeDragStop, onConnect, instance, addEdges, store, getNodes, nodes, onEdgeUpdate, onNodesChange, updateNode } = useVueFlow()

onConnect((params) => {
  params.style = {
    stroke: '#B0B0B0',
  }
  return addEdges([params])
})

onPaneReady(({ fitView }) => {
  fitView({ maxZoom: 2, offset: { x: -300, y: 0 } })
})

onEdgeUpdate(({ edge, connection }) => {
})


onNodesChange((nodes) => {
  let index = getNodes._value.findIndex(x => x.id === nodes[0].id);

  //getNodes._value[index] = getNodes._value[index]

})


const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
.investigate-canvas {
  margin-top: 0em;
  width: 100%;
  height: 65vh;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-size: 30px 30px;
  background-image: radial-gradient(circle, #b0b0b0 1px, rgba(0, 0, 0, 0) 1px);
  padding: 0.5em;
}


.main-detail-info {
  max-height: 678px;
}

.vue-flow {
  height: 100% !important;
  width: 100%;
}

.vue-flow__edge-text {
  font-size: 0.5em !important;
}

.my-node-node-title {
  font-size: 0.5em;
  margin-top: 0.5em;
  width: 10em;
  margin-left: -55%;
  margin-top: -2px;
}


.node-default-style .pi {
  font-weight: normal;
  font-size: larger;
  line-height: 1;
}

.pi{
  line-height: 1;
  bottom: auto !important;
}

.vue-flow__node-customdefault {
  max-width: 2.3em !important;
  max-height: 1.9em !important;
}

.vue-flow__node-event {
  max-width: 2.3em !important;
  max-height: 1.9em !important;
}

.vue-flow__node-incident {
  max-width: 2.3em !important;
  max-height: 1.9em !important;
}

.vue-flow__node-none {
  max-width: 2.3em !important;
  max-height: 1.9em !important;
}

.selected-node {
  border: 1px dashed #d2d2d2 !important;
  animation: heartbeat 1s infinite;
}

.p-tooltip-text {
  font-size: 0.75em !important;
}

.investigate-details {
  padding-right: 1.5em;
  padding-left: 2em;
}

.investigate-new-node {
  padding-left: 2em;
}

.investigate-edge-options {
  padding-left: 2em;
}

.collapsed-investigate {
  width: 2.5em;
  margin-bottom: 10px;
  cursor: pointer;
  height: 2em;
  margin-left: 1.5em !important;
}

.collapsed-investigate-new-node {
  margin-top: -1em !important;
  ;
  margin-left: -1.5em !important;
}

.collapsed-investigate-icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8px;
  margin-left: 15px;
  left: 0px;
}

.close-investigate-window-icon {
  position: absolute;
  left: 0;
  top: 50%;
  cursor: pointer;
  margin-top: -5px;
}

.info-card-icon-close {
  right: 0;
  cursor: pointer;
  z-index: 999;
  position: absolute !important;
  margin-right: 0.5em;
}

.aux-canvas-div {
  position: absolute;
  right: 0;
  margin-right: 1em;
  margin-top: 0em;
  padding-bottom: 0;
}

.aux-canvas-div .p-scrollpanel-content {
  padding-right: 30px;
}

.investigate-details-title {
  font-size: 1.2em;
  margin-top: 0em;
}

.investigate-details-description {
  font-size: 0.9em;
  margin-bottom: 0.5em;
  margin-top: 0.25em;
}

.category-title {
  font-size: 0.8em;
  margin-top: 0.25em;
  text-transform: uppercase;
  font-weight: bold;
}

.category-value {
  font-size: 0.9em;
}

.status-label {
  margin-top: 0.25em;
  max-width: max-content;
}

.node-options .p-button-label {
  font-size: 0.85em !important;
}

.collapsed-investigate-new-node .p-button {
  background-color: #fff !important;
  font-size: 0.85em !important;
  color: #ef6154 !important;

}

.node-options-button-new-node {
  background-color: #fff !important;
  font-size: 0.85em !important;
  color: #ef6154 !important;
  position: absolute;
  top: 0;
  margin-top: 4em;
  margin-left: .5em !important;
}

.node-options-button {
  background-color: #fff !important;
  font-size: 0.85em !important;
  color: #ef6154 !important;
}

.general-node-options .p-colorpicker-preview {
  width: 50px !important;
  height: 16px !important;
}

.node-colorpicker .p-colorpicker-preview {
  width: 50px !important;
  height: 16px !important;
}

.node-options .p-dropdown {
  height: 1.65em !important;
  font-size: 0.95em !important;
}

.node-options .p-dropdown-label {
  font-size: 0.85em !important;
  padding-bottom: 0.35em !important;
  padding-top: 0.25em !important;
}

.node-options .p-dropdown-trigger-icon {
  font-size: 0.85em !important;
}

.general-node-options .p-dropdown {
  height: 1.65em !important;
  font-size: 0.95em !important;
  width: 100% !important;
}

.general-node-options .p-dropdown-label {
  font-size: 0.85em !important;
  padding-bottom: 0.35em !important;
  padding-top: 0.25em !important;
}

.general-node-options .p-dropdown-trigger-icon {
  font-size: 0.85em !important;
}

.general-node-options textarea {
  padding: 0.25em !important;
  font-size: 0.85em !important;
}

.general-node-options input {
  padding: 0.25em !important;
  font-size: 0.85em !important;
  height: 1.65em !important;
}

.node-options label {
  font-size: 0.85em !important;
  margin-bottom: 2px !important;
  margin-left: -2px !important;
  margin-right: 1.5em !important;
  font-size: 0.85em !important;
}

.node-options .p-checkbox .p-checkbox-box {
  height: 15px !important;
  width: 15px !important;
}

.node-options .p-checkbox-icon {
  font-size: 11px;
}

.node-options .p-button-icon-left {
  font-size: 0.85em !important;
}

.general-node-options .btn-custom-cancel {
  color: #ec635f !important;
  margin-top: 0em;
  margin-right: 0.25em;
}

.node-options-title {
  font-size: 0.8em;
  margin-top: 1em;
  text-transform: uppercase;
  font-weight: 600;
  color: #b8b8b8;
}

.node-options-subtitle {
  font-size: 0.7em;
  margin-top: 0.5em;
  text-transform: uppercase;
  font-weight: bold;
}

.warning-node-div {
  position: absolute;
  top: -6px;
  right: -5px;
  background-color: #ca2f2f !important;
  border-radius: 50%;
  width: 1.1em;
  height: 1.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff00;
  font-size: 0.6em;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.side-slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.side-slide-fade-leave-active {
  transition: all 0.0s cubic-bezier(1, 0.5, 0.8, 1);
}

.side-slide-fade-enter-from,
.side-slide-fade-leave-to {
  transform: translateX(4px);
  opacity: 0;
}

/* Selected node animation */
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(.9);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}</style>
