<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer">
      <ContentLoader v-if="loading"></ContentLoader>
      <div v-else class="adversaries-container p-0">
        <h5 class="" v-if="adversaries.length == 0">{{ $t('noDataFound') }}</h5>
        <div v-else class="adversary-container" v-for="adversary in adversaries" :key="adversary.id">
          <AdversaryCard :adversary="adversary"></AdversaryCard>
        </div>  
      </div>
    </div>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.adversary-container{
  display: inline-block;
  justify-content: center;
}

</style>
