import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import { useUserStore } from '../../store/store.js'
import Chart from 'primevue/chart';
import ConfirmDialog from "primevue/confirmdialog";
import { nextTick } from "vue";
import * as $api from "../../api/api";
import TerminalService from "primevue/terminalservice";

var MyShared = {
    /* Name */
    name: "Home",
    /* Data */
    setup() {
        const confirm = useConfirm();
        return { confirm };
    },
    data() {
        return {
            user: {
                "permissions": []
            },
            steps:1,
            lastWord: '',
            textboxLocked: false,
            elastic_logo: require('@/assets/elastic.png'),
            db_logo: require('@/assets/db.jpg'),
            showPalettes: true,
            isAnUpdate: false,
            is_saved: true,
            info_colse: false,
            sources_and_models: {
                sources: [],
                models: []
            },
            /* Textarea */
            query: '',
            options: [],
            terminalOptions: [],
            /* New visualization formulary */
            main_models: [],
            selected_main_model: null,
            metrics: [{
                    name: 'Counter',
                    value: 'count'
                },
                {
                    name: 'Summarie',
                    value: 'sum'
                },
                {
                    name: 'Average',
                    value: 'avg'
                }
            ],
            selected_metric: {
                name: '',
                value: ''
            },
            slices: [{
                    name: 'Filters',
                    value: 'filters'
                },
                {
                    name: 'Terms',
                    value: 'terms'
                },
                {
                    name: 'Date histogram',
                    value: 'date_histogram'
                }
            ],
            selected_slice: {
                name: '',
                value: ''
            },
            main_fields: [],
            selected_main_field: {
                name: '',
                value: ''
            },
            fields: [],
            selected_slices_field: {
                name: '',
                value: ''
            },
            selected_xaxis_field: {
                name: '',
                value: ''
            },
            intervals: [{
                    name: 'Auto',
                    value: 'auto'
                },
                {
                    name: 'Year',
                    value: 'year'
                },
                {
                    name: 'Quarter',
                    value: 'quarter'
                },
                {
                    name: 'Month',
                    value: 'month'
                },
                {
                    name: 'Week',
                    value: 'week'
                },
                {
                    name: 'Day',
                    value: 'day'
                },
                {
                    name: 'Hour',
                    value: 'hour'
                },
                {
                    name: 'Minute',
                    value: 'minute'
                },
                {
                    name: 'Second',
                    value: 'second'
                },
                {
                    name: 'Millisecond',
                    value: 'millisecond'
                }
            ],
            selected_interval: {
                name: 'Auto',
                value: 'auto'
            },
            order_by: [{
                    name: 'Ascending',
                    value: 'asc'
                },
                {
                    name: 'Descending',
                    value: 'desc'
                }
            ],
            selected_order_by: {
                name: 'Ascending',
                value: 'asc'
            },
            x_axis: [{
                    name: 'Filters',
                    value: 'filters'
                },
                {
                    name: 'Terms',
                    value: 'terms'
                },
                {
                    name: 'Date histogram',
                    value: 'date_histogram'
                }
            ],
            selected_x_axis: {
                name: '',
                value: ''
            },
            y_axis: [],
            selected_y_axis: null,
            slices_max: 6,
            xaxis_max: 6,
            slices_boolean: false,
            slices_selected_field: null,
            slices_selected_order_by: {
                name: 'Ascending',
                value: 'asc'
            },
            other_bucket_key: '',
            slices_filters: [],
            slices_filter_string: '',

            /* --------------------------- */
            new_visualization: true,
            selectedExistingChart: null,
            exisitingLibraryOpen: false,
            existingCharts: [],
            filteredVisualizations: [],
            palettes: [],
            dashboardCreated: false,
            selectedPalette: {},
            new_color: 'ff0000',
            new_palette: [],
            selectedToUpdateId: '',
            isUpdating: false,
            toggleLibrary: false,
            chartSelected: false,
            requieredValues: false,
            dashboard_name: '',
            dashboard_desc: '',
            dashboard_id: '',
            dashboardRequiredValues: false,
            selectedChartType: '',
            new_name: '',
            new_desc: '',
            new_text: '',
            new_number: '',
            new_unit: '',
            rules: '',
            filter_label: 'filter-label-1',
            aux: '',
            tempChartAuxData: {
                chartData: {
                    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
                    datasets: [{
                        label: 'Sales',
                        data: [540, 325, 702, 620],
                        backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
                        borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
                        borderWidth: 1
                    }]
                }
            },
            unlock_value: false,
            blockChange: false,
            aux_for_delete: 0,
            temp_selected_rule: '',
            option_rules: [],
            option_rules_backup: [],
            input_for_filter: null,
            option_rules_fields: [{ name: 'events.', attributes: [{ name: 'criticality' }, { name: 'status' }] }, { name: 'incidents.', attributes: [{ name: 'criticality' }, { name: 'status' }] }, { name: 'assets.', attributes: [{ name: 'criticality' }, { name: 'risk' }] }, { name: '(' }, { name: 'not' }],
            option_rules_fields_backup: [{ name: 'eventos' }, { name: 'incidentes' }, { name: 'assets' }, { name: '(' }, { name: 'not' }],
            option_rules_association_operators: [{ name: 'AND' }, { name: 'OR' }],
            option_rules_association_operators_backup: [{ name: 'AND' }, { name: 'OR' }],
            option_rules_arithmetic_operators: [{ name: '==' }, { name: '>' }, { name: '<' }, { name: '>=' }, { name: '<=' }, { name: '!=' }, { name: ')' }],
            option_rules_arithmetic_operators_backup: [{ name: '==' }, { name: '>' }, { name: '<' }, { name: '>=' }, { name: '<=' }, { name: '!=' }, { name: ')' }],
            selectedChartToUpdate: {},
            toggleNewPalette: false,
            tempData: null,
            charts_types: [
                { name: this.$t('line'), value: 'line', img: require('@/assets/charts/line.svg'), desc: this.$t('lineDesc') },
                { name: this.$t('bar'), value: 'bar', img: require('@/assets/charts/bar.svg'), desc: this.$t('barDesc') },
                { name: this.$t('pie'), value: 'pie', img: require('@/assets/charts/pie.svg'), desc: this.$t('pieDesc') },
                { name: this.$t('library'), value: 'library', img: require('@/assets/charts/library.svg'), desc: this.$t('libraryDesc') },
                //{ name: this.$t('text'), value: 'text', img: require('@/assets/charts/text.svg'), desc: this.$t('textDesc') },
                //{ name: this.$t('number'), value: 'number', img: require('@/assets/charts/number.svg'), desc: this.$t('numberDesc') },
            ],
            layout: [],
            lineChartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
            pieChartOptions: {
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    legend: {
                        labels: {
                            boxWidth: 15,
                            color: '#495057'
                        }
                    }
                }
            },
            tempChart: {
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 5,
                "i": "0",
                "typeof": "line",
                "name": "Line",
                "chartData": {
                    "labels": [],
                    "datasets": []
                },
                "configuration": {}
            },
            clearChart: {
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 5,
                "i": "0",
                "typeof": "line",
                "name": "Line",
                "chartData": {
                    "labels": [],
                    "datasets": []
                }
            },
            textCommand: '',
            textCommandIndex: -1,
            firstTab: true,
            userCommand: '',
            aritmetic_operators: [
                { name: '=', value: '=' },
                { name: '<>', value: '<>' },
                { name: '>', value: '>' },
                { name: '<', value: '<' },
                { name: '>=', value: '>=' },
                { name: '<=', value: '<=' },
                { name: 'LIKE', value: 'LIKE' },
                { name: 'NLIKE', value: 'NLIKE' },
                { name: 'ILIKE', value: 'ILIKE' },
                { name: 'NILIKE', value: 'NILIKE' },
                { name: 'REGEXP', value: 'REGEXP' },
                { name: 'NREGEXP', value: 'NREGEXP' },
                { name: 'IREGEXP', value: 'IREGEXP' },
                { name: 'NIREGEXP', value: 'NIREGEXP' },
                { name: 'IN', value: 'IN' },
                { name: 'NOTIN', value: 'NOTIN' }
            ],
            union_operators: [
                { name: 'AND', value: 'AND' },
                { name: 'OR', value: 'OR' },
                { name: '(', value: '(' },
                { name: ')', value: ')' }
            ]

        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Chart,
        ConfirmDialog
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {
        theFormat(number) {
            return number.toFixed(2);
        },
        changeStep(num) {
            this.steps = num;
            if (this.steps == 3) {
                this.main_fields = this.onlyValidSumAndAvgFields(this.main_fields);
            }
        },
        /* -------------------------------------------------------------------------------------------------------------------- */
        /* ---------------------------------------------------- PALETTES ---------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        deleteColor(color) {
            this.new_palette = this.new_palette.filter(item => item !== color);
        },
        addNewPalette(name, new_palette) {
            console.log(this.new_palette);
            let selectedPalette = { name: name, value: name, colors: this.new_palette }
            $api.createColorPalette(selectedPalette).then(response => {
                console.log(response);
                this.palettes.push(selectedPalette);
                this.toggleNewPalette = false;
                this.new_palette = [];
                this.$toast.add({ severity: 'success', summary: this.$t('paletteCreated'), detail: this.$t('paletteCreated'), life: 3000 })
            }).catch(error => {
                console.log("[-] Error in createColorPalette", error);
                this.$toast.add({ severity: 'error', summary: this.$t('paletteNotCreated'), detail: this.$t('paletteNotCreated'), life: 3000 })
            });
        },

        /* ------------------------------------------------------------------------------------------------------------------ */
        /* ---------------------------------------------------- TERMINAL ---------------------------------------------------- */
        /* ------------------------------------------------------------------------------------------------------------------ */

        async commandHandler(text) {
            this.textCommandIndex = -1;
            this.options = this.main_fields;
            this.terminalOptions = this.main_fields;

            let res = this.checkSintaxis(text)

            TerminalService.emit('response', res);
            await nextTick();

            // get element with class p-terminal-response
            let terminal_response = document.getElementsByClassName('p-terminal-response');
            for (let i = 0; i < terminal_response.length; i++) {
                if (terminal_response[i].innerText.includes('[-] Error:')) {
                    terminal_response[i].style.color = '#d70301';
                } else if (terminal_response[i].innerText.includes('[!] Warning:')) {
                    terminal_response[i].style.color = '#edcc14';
                } else {
                    terminal_response[i].style.color = '#00b753';
                }
            }
            if (res.includes('[+] Valid qwery')) {
                this.addFilter(this.filter_label, this.stringToFilter(text));

            }

        },
        checkSintaxis(text) {

            // check if text is a valid qwery (main_fields + this.aritmetic_operators + this.union_operators)
            let words = text.split(' ');
            let res = "[+] Valid qwery: adding '" + this.filter_label + "' to list..."

            // words[0] must be a main_field
            let main_field = this.main_fields.find(main_field => main_field.name == words[0]);
            if (main_field == undefined) {
                res = "[-] Error: Not a valid qwery, '" + words[0] + "' is not a valid field";
            }

            // words[1] must be a aritmetic_operator
            let aritmetic_operator = this.aritmetic_operators.find(aritmetic_operator => aritmetic_operator.name == words[1]);
            if (aritmetic_operator == undefined) {
                res = "[-] Error: Not a valid qwery, '" + words[1] + "' is not a valid aritmetic operator";
            }

            // words[2] must be a value
            if (words[2] == undefined) {
                res = "[-] Error: Not a valid qwery, '" + words[2] + "' is not a valid value";
            }

            // if test contains ' or "
            if (words[2].includes("'") || words[2].includes('"')) {
                res = '\n [!] Warning: The value may not require quotation marks, remove them for a better result';
                TerminalService.emit('response', res);
            }
            console.log('-----------------------------');
            console.log('[+] CHECK SINTAXIS');
            console.log("TEXT: ", text);
            console.log("WORDS: ", words);
            console.log("RES: ", res);
            console.log('-----------------------------');


            return res;

        },

        updateUserCommand(event, target) {
            if (event.key == 'Tab') return;

            if (event.key == 'Enter') return;


            if (target.value == '') {
                this.userCommand = '';
                this.updateOptions(this.userCommand);
                return;
            }

            this.userCommand = target.value;

            // get last word
            let lastWord = this.getLastWord(this.userCommand);
            this.filterTerminalOptions(lastWord);
            console.log('-----------------------------');
            console.log('[+] KEY PRESS');
            console.log("USER COMMAND: ", this.userCommand);
            console.log("LAST WORD: ", lastWord);
            console.log("TERMINAL OPTIONS: ", this.terminalOptions);
            console.log('-----------------------------');

            if (event.key == 'Backspace') {
                console.log('-----------------------------');
                console.log('[-] BACKSPACE PRESS');
                console.log("USER COMMAND: ", this.userCommand);
                console.log("TERMINAL OPTIONS: ", this.terminalOptions);
                console.log('-----------------------------');
                this.textCommandIndex = -1;

                // Exception 1: if userCommand ends in " " then options are aritmetic_operators
                for (let i = 0; i < this.main_fields.length; i++) {
                    if (this.userCommand.endsWith(this.main_fields[i].name + ' ')) {
                        this.options = this.aritmetic_operators;
                        this.terminalOptions = this.aritmetic_operators;
                        return;
                    }
                }
                // Exception 2: if userCommand includes part of a main_field then options are main_fields
                for (let i = 0; i < this.main_fields.length; i++) {
                    if (this.main_fields[i].name.includes(this.userCommand) && this.main_fields[i].name != this.userCommand) {
                        this.options = this.main_fields;
                        this.terminalOptions = this.main_fields;
                        this.filterTerminalOptions(lastWord);
                        console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                        return;
                    }
                }

            }

            if (event.key == ' ') {
                this.updateOptions(this.userCommand);
                console.log('-----------------------------');
                console.log('[+] SPACE PRESS');
                console.log("USER COMMAND: ", this.userCommand);
                console.log("TERMINAL OPTIONS: ", this.terminalOptions);
                console.log('-----------------------------');
            }


        },
        tabHandle(target) {

            if (this.terminalOptions == null) return;

            // get target value
            let actualCommand = target.value;
            this.textCommandIndex++;

            console.log('-----------------------------');
            console.log('[+] TAB PRESS');
            console.log("USER COMMAND: ", this.userCommand);
            console.log("ACTUAL COMMAND: ", actualCommand);
            console.log("TERMINAL OPTIONS: ", this.terminalOptions);
            console.log('-----------------------------');

            if (this.textCommandIndex == this.terminalOptions.length) {
                this.textCommandIndex = 0;
            }

            if (this.terminalOptions.length > 0) {
                this.textCommand = this.terminalOptions[this.textCommandIndex].name;
                if (this.userCommand.endsWith(' ')) {
                    target.value = this.userCommand + this.textCommand;
                    target.dispatchEvent(new Event('input', { bubbles: true }));
                } else {
                    let aux_string = '';
                    let words = this.userCommand.split(' ');
                    for (let i = 0; i < words.length - 1; i++) {
                        aux_string += words[i] + ' ';
                    }
                    console.log("AUX STRING: ", aux_string);
                    target.value = aux_string + this.textCommand;
                    target.dispatchEvent(new Event('input', { bubbles: true }));
                }

            }

        },

        filterTerminalOptions(lastWord) {
            if (this.terminalOptions == null) return;

            // filter options by last word
            this.terminalOptions = this.options.filter(option => option.name.startsWith(lastWord));
        },

        /* -------------------------------------------------------------------------------------------------------------------- */
        /* ---------------------------------------------------- USER QWERY ---------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        getInput() {
            this.chartSelected = true;
            this.option_rules_backup = this.option_rules;
            if (this.selectedChartType == 'library') {
                this.exisitingLibraryOpen = true;
            } else {
                this.exisitingLibraryOpen = false;
            }
            // wait for the element to be rendered
            setTimeout(() => {
                this.input_for_filter = document.querySelector('[aria-controls="changeInputFocus_list"]');
            }, 1000);
        },
        addParamToTextBox(param) {
            this.slices_filter_string += param + ' ';
            console.log("OPTIONS: ", this.options);
            this.updateOptions(this.slices_filter_string);
        },
        getLastWord(string) {
            // if string ends in " " then return ""
            if (string.endsWith(' ')) {
                return ' ';
            }

            let words = string.split(' ');
            return words[words.length - 1];
        },
        updateOptions(qwery) {
            // console.log("CONSULTA: ", qwery);
            // this.options => campos + "("
            // this.aritmetic_operators => operadores aritmeticos: "=", "<>", ">", "<", ">=", "<=", "LIKE", "NLIKE", "ILIKE", "NILIKE", "REGEXP", "NREGEXP", "IREGEXP", "NIREGEXP", "IN", "NOTIN"
            // this.union_operators => operadores de union: "and", "or", "(", ")"
            console.log('[*] UPDATE OPTIONS BASED ON: ', qwery);

            // check if any main_model includes qwery -> options are main_fields
            for (let i = 0; i < this.main_fields.length; i++) {
                if (this.main_fields[i].name.includes(qwery) && this.main_fields[i].name != qwery) {
                    this.options = this.main_fields;
                    this.terminalOptions = this.main_fields;
                    console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                    return;
                }
            }

            // check if qwery is empty -> options are fields
            if (qwery == '' || qwery == ' ') {
                this.options = this.main_fields;
                this.terminalOptions = this.main_fields;
                console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                return;
            }

            // check if qwery ends in "(" -> options are fields
            if (qwery.endsWith("( ")) {
                this.options = this.main_fields;
                this.terminalOptions = this.main_fields;
                console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                return;
            }

            // check if qwery ends in one field -> options are aritmetic operators
            for (let i = 0; i < this.main_fields.length; i++) {
                if (qwery.endsWith(this.main_fields[i].name + ' ')) {
                    this.options = this.aritmetic_operators;
                    this.terminalOptions = this.aritmetic_operators;
                    console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                    return;
                }
            }

            // check if qwery ends in one aritmetic operator -> options null
            for (let i = 0; i < this.aritmetic_operators.length; i++) {
                if (qwery.endsWith(this.aritmetic_operators[i].name + ' ')) {
                    this.options = null;
                    this.terminalOptions = null;
                    console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                    return;
                }
            }

            // check if qwery ends in one union operator -> options are fields
            for (let i = 0; i < this.union_operators.length; i++) {
                if (qwery.endsWith(this.union_operators[i].name + ' ')) {
                    this.options = this.main_fields;
                    this.terminalOptions = this.main_fields;
                    console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
                    return;
                }
            }

            // if qwery ends in a value
            this.options = this.union_operators;
            this.terminalOptions = this.union_operators;
            console.log('[*] TERMINAL OPTIONS: ', this.terminalOptions);
        },
        /* -------------------------------------------------------------------------------------------------------------------- */
        /* -------------------------------------------------- VIZUALIZATION -------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        changeLabels() {
            // find values in array tempChart.data.labels and substitute as '1' = low, '2' = medium, '3' = high, '4' = critical, '0' = info
            if (this.tempChart.data)
                for (let i = 0; i < this.tempChart.data.labels.length; i++) {
                    if (this.tempChart.data.labels[i] == 1) {
                        this.tempChart.data.labels[i] = this.$t('low');
                        if (this.tempChart.data.datasets[0].backgroundColor) {
                            this.tempChart.data.datasets[0].backgroundColor[i] = '#6dc193';
                            if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                                this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#539470';

                        }
                        if (this.tempChart.data.datasets[0].borderColor) {
                            this.tempChart.data.datasets[0].borderColor = '#6dc193';
                        }
                    } else if (this.tempChart.data.labels[i] == 2) {
                        this.tempChart.data.labels[i] = this.$t('medium');
                        if (this.tempChart.data.datasets[0].backgroundColor) {
                            this.tempChart.data.datasets[0].backgroundColor[i] = '#f9c851';
                            if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                                this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#d6a32a';
                        }
                        if (this.tempChart.data.datasets[0].borderColor) {
                            this.tempChart.data.datasets[0].borderColor = '#f9c851';
                        }
                    } else if (this.tempChart.data.labels[i] == 3) {
                        this.tempChart.data.labels[i] = this.$t('high');
                        if (this.tempChart.data.datasets[0].backgroundColor) {
                            this.tempChart.data.datasets[0].backgroundColor[i] = '#f86c6b';
                            if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                                this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#d64a4a';
                        }
                        if (this.tempChart.data.datasets[0].borderColor) {
                            this.tempChart.data.datasets[0].borderColor = '#f86c6b';
                        }
                    } else if (this.tempChart.data.labels[i] == 4) {
                        this.tempChart.data.labels[i] = this.$t('critical');
                        if (this.tempChart.data.datasets[0].backgroundColor) {
                            this.tempChart.data.datasets[0].backgroundColor[i] = '#b83c32';
                            if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                                this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#961f1a';
                        }
                        if (this.tempChart.data.datasets[0].borderColor) {
                            this.tempChart.data.datasets[0].borderColor = '#b83c32';
                        }
                    } else if (this.tempChart.data.labels[i] == 0) {
                        this.tempChart.data.labels[i] = this.$t('info');
                        if (this.tempChart.data.datasets[0].backgroundColor) {
                            this.tempChart.data.datasets[0].backgroundColor[i] = '#aeb5c1';
                            if (this.tempChart.data.datasets[0].hoverBackgroundColor)
                                this.tempChart.data.datasets[0].hoverBackgroundColor[i] = '#8c939f';
                        }
                        if (this.tempChart.data.datasets[0].borderColor) {
                            this.tempChart.data.datasets[0].borderColor = '#aeb5c1';
                            this.tempChart.data.datasets[0].fill = false;
                        }
                    }
                }
            if (this.tempChart.data.datasets) {
                for (let i = 0; i < this.tempChart.data.datasets.length; i++) {
                    if (this.tempChart.data.datasets[i].label == 1 || this.tempChart.data.datasets[i].label == 'Low') {
                        this.tempChart.data.datasets[i].label = this.$t('low');
                        this.tempChart.data.datasets[i].borderColor = '#6dc193';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#6dc193';
                    } else if (this.tempChart.data.datasets[i].label == 2 || this.tempChart.data.datasets[i].label == 'Medium') {
                        this.tempChart.data.datasets[i].label = this.$t('medium');
                        this.tempChart.data.datasets[i].borderColor = '#f9c851';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#f9c851';
                    } else if (this.tempChart.data.datasets[i].label == 3 || this.tempChart.data.datasets[i].label == 'High') {
                        this.tempChart.data.datasets[i].label = this.$t('high');
                        this.tempChart.data.datasets[i].borderColor = '#f86c6b';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#f86c6b';

                    } else if (this.tempChart.data.datasets[i].label == 4 || this.tempChart.data.datasets[i].label == 'Critical') {
                        this.tempChart.data.datasets[i].label = this.$t('critical');
                        this.tempChart.data.datasets[i].borderColor = '#b83c32';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#b83c32';
                    } else if (this.tempChart.data.datasets[i].label == 0 || this.tempChart.data.datasets[i].label == 'Info') {
                        this.tempChart.data.datasets[i].label = this.$t('info');
                        this.tempChart.data.datasets[i].borderColor = '#aeb5c1';
                        if (this.tempChart.configuration.type == 'line')
                            this.tempChart.data.datasets[i].backgroundColor = '#91919126';
                        if (this.tempChart.configuration.type == 'bar')
                            this.tempChart.data.datasets[i].backgroundColor = '#aeb5c1';
                    }
                }
            }
        },
        checkRequieredValues(type) {
            switch (type) {
                case 'line':
                    if (this.new_name == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'bar':
                    if (this.new_name == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'pie':
                    if (this.new_name == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'text':
                    if (this.new_name == '' || this.new_text == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
                case 'number':
                    if (this.new_name == '' || this.new_number == '' || this.new_unit == '') {
                        this.requieredValues = true;
                        return false;
                    }
                    break;
            }
            return true;
        },
        loadSelectedVisualization(type, id) {
            console.log("LOADING VISUALIZATION: ", this.selectedExistingChart);
            this.selectedChartType = type;
            $api.getOneVisualization(id).then(response => {
                console.log("LOADED VISUALIZATION: ", response);
                this.new_name = response.name;
                this.new_desc = response.description;
                this.tempChart = response;
                this.tempChart.i = response.id;
                this.tempChart.id = response.id;
                this.tempChart.configuration.type = type;
                this.tempChartAuxData.chartData = response.data;
                this.changeLabels();
            }).catch(error => {
                console.log(error);
            });
        },
        filterExistingVisualizationsByType(type) {
            this.filteredVisualizations = [];
            this.existingCharts.forEach(visualization => {
                if (visualization.configuration.type == type) {
                    this.filteredVisualizations.push(visualization);
                }
            });
        },


        /* -------------------------------------------------------------------------------------------------------------------- */
        /* ------------------------------------------------ ADD VISUALIZATION ------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        addChart(type, name, desc, text, number, unit) {

            console.log("ADDING CHART: ", type, name, desc, text, number, unit);

            // Check if the visualization is new or is an existing one
            if (!this.new_visualization) {
                this.tempChart.i = this.tempChart.id
                if (type == 'pie') {
                    this.tempChart.h = 5;
                    this.tempChart.w = 3;
                    this.tempChart.x = 0;
                    this.tempChart.y = 0;
                } else if (type == 'text' || type == 'number') {
                    this.tempChart.h = 4;
                    this.tempChart.w = 3;
                    this.tempChart.x = 0;
                    this.tempChart.y = 0;
                } else {
                    this.tempChart.h = 5;
                    this.tempChart.w = 5;
                    this.tempChart.x = 0;
                    this.tempChart.y = 0;
                }
                this.addChartToLayout(this.tempChart, false);
                return;
            }

            console.log("SELECTED MAIN FIELD", this.selected_main_field);


            // If the visualization is new...

            // Check if data source is model or source!!
            let data_source = {};

            this.sources_and_models.models.forEach(item => {
                if (item == this.selected_main_model) {
                    data_source = {
                        "model": this.selected_main_model
                    }
                }
            });

            this.sources_and_models.sources.forEach(item => {
                if (item == this.selected_main_model) {
                    data_source = {
                        "source": this.selected_main_model
                    }
                }
            });

            let visualization = {
                "name": name,
                "description": desc,
                "data_source": data_source,
                "configuration": {
                    "type": this.selectedChartType,
                    "metrics": {
                        "type": this.selected_metric.value,
                        "field": (this.selected_main_field) ? this.selected_main_field.name : null
                    },
                    "slices": {
                        "type": this.selected_slice.value,
                        "field": this.selected_slices_field.name,
                        "interval": (this.selected_interval) ? this.selected_interval.value : null,
                        "order_by": {
                            "type": "count",
                            "direction": this.slices_selected_order_by.value,
                        },
                        "max": this.slices_max,
                        "filters": this.slices_filters, // create this filters
                        "other_bucket_key": this.other_bucket_key,
                    },
                    "x_axis": {
                        "type": this.selected_x_axis.value,
                        "field": this.selected_xaxis_field.name,
                        "interval": (this.selected_interval) ? this.selected_interval.value : null,
                        "order_by": {
                            "type": "count",
                            "direction": this.selected_order_by.value,
                        },
                        "max": this.xaxis_max,
                        "filters": this.slices_filters, // create this filters
                        "other_bucket_key": this.other_bucket_key,
                    },
                },
                "color_palette_id": this.selectedPalette.id,
            }

            if (type == 'pie') {
                visualization.configuration.x_axis = {};
            } else if (!this.slices_boolean) {
                visualization.configuration.slices = null;
            } else {
                visualization.configuration.slices.type = 'terms'
            }

            console.log("VISUALIZATION TO CREATE: ", visualization);

            // 1. CREATE VISUALIZATION IN API
            $api.createVisualization(visualization).then(response => {
                this.tempChart = response;
                $api.getOneVisualization(this.tempChart.id).then(async(response) => {
                    this.tempChart = response;
                    // 2. IF VISUALIZATION IS CREATED CORRECTLY THEN SET THE VALUES
                    // 2.1. SET THE SIZE OF THE VISUALIZATION
                    if (type == 'pie') {
                        this.tempChart.h = 5;
                        this.tempChart.w = 3;
                        this.tempChart.x = 0;
                        this.tempChart.y = 0;
                    } else if (type == 'text' || type == 'number') {
                        this.tempChart.h = 4;
                        this.tempChart.w = 3;
                        this.tempChart.x = 0;
                        this.tempChart.y = 0;
                    } else {
                        this.tempChart.h = 5;
                        this.tempChart.w = 5;
                        this.tempChart.x = 0;
                        this.tempChart.y = 0;
                    }

                    // 2.2. SET THE ID OF THE VISUALIZATION
                    this.tempChart.i = this.tempChart.id
                    console.log("VISUALIZATION CREATED: ", this.tempChart);

                    // 2.3. CHANGE THE LABELS OF THE VISUALIZATION
                    this.changeLabels();

                    // 3. ADD THE VISUALIZATION TO THE LAYOUT
                    this.addChartToLayout(this.tempChart, true);

                    /*
                    for (let i = 0; i < this.layout.length; i++) {
                        await this.refreshChart(this.layout[i].id);
                    }
                    */

                }).catch((err) => {
                    this.$toast.add({ severity: 'error', summary: this.$t('visualizationNotCreated'), detail: err.response.data.detail })
                    console.log(`[-] Error (getOneVisualization): ${err}`);
                    $api.deleteVisualization(this.tempChart.id).then(response => {
                        console.log("[+] Visualization deleted");
                    }).catch(error => {
                        console.log(`[-] Error (deleteVisualization): ${error}`);
                    });
                });

            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('visualizationNotCreated'), detail: error.response.data.detail })
                console.log("[-] Error in createVisualization", error.response.data.detail);
            });
        },
        addChartToLayout(chart, temp_data) { // change this when filters/rules are implemented
            // ADD THE VISUALIZATION TO THE LAYOUT
            this.toggleLibrary = false;
            this.chartSelected = false;
            this.requieredValues = false;
            // loop through the layout to check if the chart is already in the layout
            for (let i = 0; i < this.layout.length; i++) {
                if (this.layout[i].i == chart.i) {
                    this.$toast.add({ severity: 'error', summary: this.$t('chartAlreadyInLayout'), detail: this.$t('chartAlreadyInLayoutDetail'), life: 5000 })
                    return;
                }
            }
            switch (chart.configuration.type) {
                case 'line':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, chartData: (temp_data) ? chart.data : this.tempChartAuxData.chartData }); /* Este chartdata sera el que me devuelva al hacer la peticion de los filtros */
                    break;
                case 'bar':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, chartData: (temp_data) ? chart.data : this.tempChartAuxData.chartData });
                    break;
                case 'pie':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, chartData: (temp_data) ? chart.data : this.tempChartAuxData.chartData });
                    break;
                case 'text':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, text: text });
                    break;
                case 'number':
                    this.layout.push({ x: chart.x, y: chart.y, w: chart.w, h: chart.h, i: chart.i, typeof: chart.configuration.type, name: chart.name, desc: chart.description, number: number, unit: unit });
                    break;
                default:
                    break;
            }
            this.toggleLibrary = false;
            this.chartSelected = false;
            this.requieredValues = false;
            this.new_visualization = true;
        },

        /* -------------------------------------------------------------------------------------------------------------------- */
        /* ---------------------------------------------- UPDATE VISUALIZATION ------------------------------------------------ */
        /* -------------------------------------------------------------------------------------------------------------------- */
        tempPrint(a) {
            console.log('------------------------------>', a);
        },
        updateSelectedChartToUpdate() {
            this.selectedChartToUpdate.chartData = null // Aqui iria el chartData que me devuelva la peticion de los filtros
        },
        // Set data in form to update chart
        openUpdateChart(i) {
            this.exisitingLibraryOpen = false;
            this.isAnUpdate = true;
            // move to top of the page
            window.scrollTo(0, 0);

            this.isUpdating = true;

            this.selectedToUpdateId = i;
            // find chart in layout
            let chart = this.layout.find(element => element.i == i);
            this.selectedChartToUpdate = chart;
            //console.log('Se ha selecionado para actualizar: ' + this.selectedChartToUpdate.name);
            //console.log(this.selectedChartToUpdate);
            this.selectedChartType = chart.typeof
            this.new_name = chart.name;
            this.new_desc = chart.desc;
            this.new_text = chart.text;
            this.new_number = chart.number;
            this.new_unit = chart.unit;
            this.tempChart = chart;
            $api.getOneVisualization(i).then(response => {
                this.selected_x_axis = {
                    name: '',
                    value: ''
                };

                this.selected_slice = {
                    name: '',
                    value: ''
                }

                console.log("VISUALIZATION TO BE UPDATED: ", response);

                //this.tempChartAuxData = response;
                this.selected_main_model = this.main_models.find(element => element == response.data_source.model);
                this.getMainFields(this.selected_main_model, false)
                this.selected_metric = this.metrics.find(element => element.value == response.configuration.metrics.type);
                this.selected_main_field = this.main_fields.find(element => element.name == response.configuration.metrics.field);
                this.selectedPalette = this.palettes.find(element => element.id == response.color_palette.id);
                this.other_bucket_key = (response.configuration.x_axis) ? response.configuration.x_axis.other_bucket_key : 'Other';
                this.showPalettes = true;


                if ((response.configuration.metrics && (response.configuration.metrics.field == 'severity' || response.configuration.metrics.field == 'criticality')) ||
                    (response.configuration.slices && (response.configuration.slices.field == 'severity' || response.configuration.slices.field == 'criticality')) ||
                    (response.configuration.x_axis && (response.configuration.x_axis.field == 'severity' || response.configuration.x_axis.field == 'criticality'))) {
                    this.showPalettes = false;
                    this.tempChartAuxData.chartData.datasets[0].backgroundColor = ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32']
                    this.tempChartAuxData.chartData.datasets[0].borderColor = ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32']
                } else {
                    // get 5 random colors from this.selectedPalette 
                    this.tempChartAuxData.chartData.datasets[0].backgroundColor = [];
                    this.tempChartAuxData.chartData.datasets[0].borderColor = [];
                    for (let i = 0; i < 5; i++) {
                        let random = Math.floor(Math.random() * this.selectedPalette.colors.length);
                        this.tempChartAuxData.chartData.datasets[0].backgroundColor.push(this.selectedPalette.colors[random]);
                        this.tempChartAuxData.chartData.datasets[0].borderColor.push(this.selectedPalette.colors[random]);
                    }
                }

                if (response.configuration.type == 'pie') {
                    this.selected_slice = this.slices.find(element => element.value == response.configuration.slices.type);
                    this.selected_slices_field = (this.fields.find(element => element.name == response.configuration.slices.field)) || []; /* BUG FIX ? */
                    console.log("selected_slices_field", this.selected_slices_field);

                    if (response.configuration.slices.interval) {
                        this.selected_interval = this.intervals.find(element => element.value == response.configuration.slices.interval);
                    } else {
                        this.selected_interval = this.intervals[0];
                    }
                    if (response.configuration.slices.order_by) {
                        this.slices_selected_order_by = this.order_by.find(element => element.value == response.configuration.slices.order_by.direction);
                    } else {
                        this.slices_selected_order_by = this.order_by[0];
                    }
                    this.slices_max = response.configuration.slices.max;
                    this.slices_filters = response.configuration.slices.filters;
                    this.other_bucket_key = response.configuration.slices.other_bucket_key;
                }
                if (response.configuration.type == 'bar' || response.configuration.type == 'line') {
                    this.slices_boolean = response.configuration.slices != null;
                    this.selected_x_axis = this.x_axis.find(element => element.value == response.configuration.x_axis.type);
                    this.selected_xaxis_field = (this.fields.find(element => element.name == response.configuration.x_axis.field))|| []; /* BUG FIX ? */
                    this.xaxis_max = response.configuration.x_axis.max;
                    this.slices_filters = response.configuration.x_axis.filters;

                    if (response.configuration.x_axis.order_by)
                        this.selected_order_by = this.order_by.find(element => element.value == response.configuration.x_axis.order_by.direction);

                    if (response.configuration.x_axis) {
                        this.selected_interval = this.intervals.find(element => element.value == response.configuration.x_axis.interval);
                    } else {
                        this.selected_interval = this.intervals[0];
                    }
                    if (this.slices_boolean) {
                        this.selected_slices_field = (this.slices_fields.find(element => element.name == response.configuration.slices.field));
                        //console.log("selected_slices_field", this.selected_slices_field.name);
                        this.slices_selected_order_by = this.order_by.find(element => element.value == response.configuration.slices.order_by.direction);
                        this.slices_max = response.configuration.slices.max;
                    }
                }
                //this.checkIfAnyFieldIsSeverityOrCriticality();
                this.chartSelected = true;
                this.isUpdating = true;
            }).catch(error => {
                console.log("[-] Error in getVisualization", error);
            });
        },
        // Update chart
        updateChart(i, selectedChartType, new_name, new_desc, new_text, new_number, new_unit) {
            // 1. Create de update visualization object
            let visualization = {
                "name": new_name,
                "description": new_desc,
                "data_source": {
                    "model": this.selected_main_model
                },
                "configuration": {
                    "type": this.selectedChartType,
                    "metrics": {
                        "type": this.selected_metric.value,
                        "field": (this.selected_main_field) ? this.selected_main_field.name : null
                    },
                    "slices": {
                        "type": this.selected_slice.value,
                        "field": (this.selected_slices_field) ? this.selected_slices_field.name : null,
                        "interval": (this.selected_interval) ? this.selected_interval.value : null,
                        "order_by": {
                            "type": "count",
                            "direction": this.slices_selected_order_by.value,
                        },
                        "max": this.slices_max,
                        "filters": this.slices_filters, // create this filters
                        "other_bucket_key": this.other_bucket_key,
                    },
                    "x_axis": {
                        "type": this.selected_x_axis.value,
                        "field": (this.selected_xaxis_field) ? this.selected_xaxis_field.name : null,
                        "interval": (this.selected_interval) ? this.selected_interval.value : null,
                        "order_by": {
                            "type": "count",
                            "direction": this.selected_order_by.value,
                        },
                        "max": this.xaxis_max,
                        "filters": this.slices_filters, // create this filters
                        "other_bucket_key": this.other_bucket_key,
                    },
                },
                "color_palette_id": this.selectedPalette.id,
            }

            if (selectedChartType == 'pie') {
                visualization.configuration.x_axis = {};
            } else if (!this.slices_boolean) {
                visualization.configuration.slices = null;
            } else {
                visualization.configuration.slices.type = 'terms'
            }

            //console.log('VISUALIZATION READY TO UPDATE: ', visualization);

            // 2. Update visualization in the backend
            $api.updateVisualization(i, visualization).then(response => {
                this.$toast.add({ severity: 'success', summary: this.$t('visualizationUpdated'), detail: this.$t('visualizationUpdatedDetail'), life: 3000 })
                console.log("[+] Visualization updated: ", response);
                // 3. Get the updated visualization from the backend and update the layout
                $api.getOneVisualization(i).then(response => {
                    // loop layout
                    this.layout.forEach(element => {
                        if (element.i == i) {
                            switch (selectedChartType) {
                                case 'line':
                                    element.name = new_name;
                                    element.desc = new_desc;
                                    element.chartData = response.data;
                                    break;
                                case 'bar':
                                    element.name = new_name;
                                    element.desc = new_desc;
                                    element.chartData = response.data;
                                    break;
                                case 'pie':
                                    element.name = new_name;
                                    element.desc = new_desc;
                                    element.chartData = response.data;
                                    break;
                                case 'text':
                                    element.name = new_name;
                                    element.desc = new_desc;
                                    element.text = new_text;
                                    break;
                                case 'number':
                                    element.name = new_name;
                                    element.desc = new_desc;
                                    element.number = new_number;
                                    element.unit = new_unit;
                                    break;
                                default:
                                    break;
                            }
                        }
                    });
                });
                this.isUpdating = false;
                this.isAnUpdate = false;
                this.chartSelected = false;
                this.requieredValues = false;
                this.changeLabels();
                this.clearVariables();
            }).catch(error => {
                this.$toast.add({ severity: 'error', summary: this.$t('visualizationNotUpdated'), detail: error.response.data.detail, life: 3000 })
                console.log("[-] Error in updateVisualization", error);
            });
        },


        /* -------------------------------------------------------------------------------------------------------------------- */
        /* ------------------------------------------- REMOVE VIS. FROM DASHBOARD --------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        removeChart(i) {
            this.$confirm.require({
                message: this.$t('deleteConfirmationAlert'),
                header: this.$t('deleteConfirmation'),
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.layout = this.layout.filter(element => element.i != i);
                    this.chartSelected = false;
                    this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('recordDeleted'), life: 3000 });
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
                }
            });
        },

        /* -------------------------------------------------------------------------------------------------------------------- */
        /* -------------------------------------------------- UPDATE LAYOUT --------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        updateLayout() {
            // used for visualizations not been to small
            this.resetDashboardSaved();
            this.is_saved = false;
            ////console.log(this.layout);
            for (let i = 0; i < this.layout.length; i++) {
                switch (this.layout[i].typeof) {
                    case 'pie':
                        if (this.layout[i].w < 3 || this.layout[i].h < 5) {
                            this.layout[i].w = 3;
                            this.layout[i].h = 5;
                        }
                        break;
                    case 'line':
                        if (this.layout[i].w < 5 || this.layout[i].h < 5) {
                            this.layout[i].w = 5;
                            this.layout[i].h = 5;
                        }
                        break;
                    case 'bar':
                        if (this.layout[i].w < 5 || this.layout[i].h < 5) {
                            this.layout[i].w = 5;
                            this.layout[i].h = 5;
                        }
                        break;
                }
            }

        },

        async refreshChart(id) { /* DEPRECATED */
            //await nextTick();
            //console.log('refresh chart: ' + id);
            // find with id in layout
            let element = document.getElementById(id);
            //console.log('element: ', element);
            let temp_height = document.getElementById(id).scrollHeight;
            // get canvas in element 
            let canvas = element.getElementsByTagName('canvas')[0];
            //console.log('card: ' + temp_height);
            // remove px from temp_height and convert to number
            //temp_height = parseInt(temp_height.replace('px', ''));
            // resize canvas
            canvas.style.height = (temp_height - 55) + 'px';
            //console.log('canvas: ' + canvas.style.height);
        },
        /* -------------------------------------------------------------------------------------------------------------------- */
        /* -------------------------------------------------- SAVE DASHBOARD -------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        saveDashboard(dashboard_name, dashboard_desc) {
            // 1. CHECK IF DASHBOARD IS CREATED
            if (!this.dashboardCreated) {
                // 2. IF NOT, CREATE DASHBOARD
                $api.createDashboard({
                    "name": dashboard_name,
                    "description": dashboard_desc,
                }).then(response => {
                    console.log("CREATE DASHBOARD: ", response);
                    // 2.1 SAVE DASHBOARD ID
                    this.dashboard_id = response.id;
                    // 2.2 SET DASHBOARD CREATED TO TRUE
                    this.dashboardCreated = true;
                    // 2.3 SET NEW ROUTE
                    this.$router.push({ path: 'dashboard-creator', query: { id: this.dashboard_id } });
                    this.dashboardRequiredValues = false;
                    // 3. LOOP LAYOUT AND UPDATE VISUALIZATION DASHBOARD (where goes the chart in the layout)
                    let save_flag0 = true;
                    let ids0 = [];
                    this.layout.forEach(element => {
                        ids0.push(element.i);
                        $api.updateVisualizationDashboard({
                            "dashboard_id": this.dashboard_id,
                            "visualization_id": element.i,
                            "x": element.x,
                            "y": element.y,
                            "w": element.w,
                            "h": element.h,
                        }).then(response => {
                            console.log("CREATE VISUALIZATION DASHBOARD: ", response);
                            // Toast
                            //this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('dashboardSaved'), life: 3000 })
                        }).catch(error => {
                            save_flag0 = false;
                            //this.$toast.add({ severity: 'error', summary: this.$t('dashboardNotSaved'), detail: error.response.data.detail, life: 3000 })
                            console.log("[-] Error in createVisualizationDashboard", error);
                        });
                    });
                    // 4. CHECK FLAG 
                    if (save_flag0) {
                        // 4.1 IF TRUE, DASHBOARD SAVED
                        this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('dashboardSaved'), life: 3000 })
                        this.setDashboardSaved();
                        this.is_saved = true;
                        // 5. UPDATE DASHBOARD
                        $api.updateDashboard(this.dashboard_id, {
                            "name": dashboard_name,
                            "description": dashboard_desc,
                            "visualizations": ids0
                        }).then(response => {
                            console.log("UPDATE DASHBOARD: ", response);
                        }).catch(error => {
                            console.log("[-] Error in updateDashboard", error);
                        });
                    } else {
                        // 4.2 IF FALSE, DASHBOARD NOT SAVED
                        this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('dashboardNotSaved'), life: 3000 })
                    }
                }).catch(error => {
                    this.$toast.add({ severity: 'error', summary: this.$t('dashboardNotSaved'), detail: error.response.data.detail, life: 3000 })
                    console.log("[-] Error in createDashboard", error);
                });
            } else {
                this.dashboardRequiredValues = false;
                // 2. IF YES, UPDATE DASHBOARD
                // 2.1 LOOP LAYOUT AND UPDATE VISUALIZATION DASHBOARD (where goes the chart in the layout)
                let save_flag1 = true;
                let ids1 = [];
                this.layout.forEach(element => {
                    ids1.push(element.i);
                    $api.updateVisualizationDashboard({
                        "dashboard_id": this.dashboard_id,
                        "visualization_id": element.i,
                        "x": element.x,
                        "y": element.y,
                        "w": element.w,
                        "h": element.h,
                    }).then(response => {
                        console.log("CREATE VISUALIZATION DASHBOARD: ", response);
                        // Toast
                        //this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('dashboardSaved'), life: 3000 })
                    }).catch(error => {
                        console.log("[-] Error in createVisualizationDashboard", error);
                        // Toast
                        //this.$toast.add({ severity: 'error', summary: this.$t('dashboardNotSaved'), detail: error.response.data.detail, life: 3000 })
                        save_flag1 = false;
                    });
                });
                if (save_flag1) {
                    // 4.1 IF TRUE, DASHBOARD SAVED
                    this.$toast.add({ severity: 'success', summary: this.$t('success'), detail: this.$t('dashboardSaved'), life: 3000 })
                    this.setDashboardSaved();
                    this.is_saved = true;
                    // 5 UPDATE DASHBOARD
                    $api.updateDashboard(this.dashboard_id, {
                        "name": dashboard_name,
                        "description": dashboard_desc,
                        "visualizations": ids1
                    }).then(response => {
                        console.log("UPDATE DASHBOARD: ", response);
                    }).catch(error => {
                        console.log("[-] Error in updateDashboard", error);
                    });
                } else {
                    // 4.2 IF FALSE, DASHBOARD NOT SAVED
                    this.$toast.add({ severity: 'error', summary: this.$t('error'), detail: this.$t('dashboardNotSaved'), life: 3000 })
                }
            }
        },

        /* -------------------------------------------------------------------------------------------------------------------- */
        /* ------------------------------------------------------- FILTER ----------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        addFilter(label, filters) {
            this.slices_filters.push({
                label: label,
                filters: filters
            });
        },
        deleteFilter(index) {
            this.slices_filters.splice(index, 1);
        },
        stringToFilter(string) {
            // input format: severity=1 and (severity=2 or severity=3)
            // output format: [{"type": "query","field": "severity","operator": "=","value": "1"}, {"type": "operator","value": "and"}]

            // REMOVE SPACES BEFORE AND AFTER AN ARITHMETIC OPERATOR (>, <, >=, <=, =, !=, <>, LIKE, NLIKE, ILIKE, NILIKE, REGEXP, NREGEXP, IREGEXP, NIREGEXP, IN, NOTIN )
            string = string.replace(/ <> /g, '<>'); // NOT EQUAL TO
            string = string.replace(/<>/g, '<>');
            string = string.replace(/ <>/g, '<>');
            string = string.replace(/ >= /g, '>='); // GREATER THAN OR EQUAL TO
            string = string.replace(/>= /g, '>=');
            string = string.replace(/ >=/g, '>=');
            string = string.replace(/ <= /g, '<='); // LESS THAN OR EQUAL TO
            string = string.replace(/<= /g, '<=');
            string = string.replace(/ <=/g, '<=');
            string = string.replace(/ > /g, '>'); // GREATER THAN
            string = string.replace(/> /g, '>');
            string = string.replace(/ >/g, '>');
            string = string.replace(/ < /g, '<'); // LESS THAN
            string = string.replace(/< /g, '<');
            string = string.replace(/ </g, '<');
            string = string.replace(/ != /g, '!='); // NOT EQUAL TO
            string = string.replace(/!= /g, '!=');
            string = string.replace(/ !=/g, '!=');
            string = string.replace(/ = /g, '='); // EQUAL TO
            string = string.replace(/= /g, '=');
            string = string.replace(/ =/g, '=');
            string = string.replace(/ NILIKE /g, 'NILIKE'); // NOT ILIKE
            string = string.replace(/NILIKE /g, 'NILIKE');
            string = string.replace(/ NILIKE/g, 'NILIKE');
            string = string.replace(/ ILIKE /g, 'ILIKE'); // ILIKE
            string = string.replace(/ILIKE /g, 'ILIKE');
            string = string.replace(/ ILIKE/g, 'ILIKE');
            string = string.replace(/ NLIKE /g, 'NLIKE'); // NOT LIKE
            string = string.replace(/NLIKE /g, 'NLIKE');
            string = string.replace(/ NLIKE/g, 'NLIKE');
            string = string.replace(/ LIKE /g, 'LIKE'); // LIKE
            string = string.replace(/LIKE /g, 'LIKE');
            string = string.replace(/ LIKE/g, 'LIKE');
            string = string.replace(/ NREGEXP /g, 'NREGEXP'); // NOT REGEXP
            string = string.replace(/NREGEXP /g, 'NREGEXP');
            string = string.replace(/ NREGEXP/g, 'NREGEXP');
            string = string.replace(/ REGEXP /g, 'REGEXP'); // REGEXP
            string = string.replace(/REGEXP /g, 'REGEXP');
            string = string.replace(/ REGEXP/g, 'REGEXP');
            string = string.replace(/ NIREGEXP /g, 'NIREGEXP'); // NOT IREGEXP
            string = string.replace(/NIREGEXP /g, 'NIREGEXP');
            string = string.replace(/ NIREGEXP/g, 'NIREGEXP');
            string = string.replace(/ IREGEXP /g, 'IREGEXP'); // IREGEXP
            string = string.replace(/IREGEXP /g, 'IREGEXP');
            string = string.replace(/ IREGEXP/g, 'IREGEXP');
            string = string.replace(/ NOTIN /g, 'NOTIN'); // NOTIN
            string = string.replace(/NOTIN /g, 'NOTIN');
            string = string.replace(/ NOTIN/g, 'NOTIN');
            string = string.replace(/ IN /g, 'IN'); // IN
            string = string.replace(/IN /g, 'IN');
            string = string.replace(/ IN/g, 'IN');

            console.log("STRING with no SPACES: ", string);

            let filter = [];
            let aux = string.split(' ');

            aux.forEach(element => {

                // CHECK IF element CONTAINS AN ARITHMETIC OPERATOR (>, <, >=, <=, =, !=)
                if ((element.includes('>')) || element.includes('<') || element.includes('>=') || element.includes('<=') || element.includes('=') || element.includes('!=') || element.includes('<>') || element.includes('LIKE') || element.includes('NLIKE') || element.includes('ILIKE') || element.includes('NILIKE') || element.includes('REGEXP') || element.includes('NREGEXP') || element.includes('IREGEXP') || element.includes('NIREGEXP') || element.includes('IN') || element.includes('NOTIN')) {
                    // SWITCH CASE TO CHECK WHICH ARITHMETIC OPERATOR IS IN THE STRING
                    let aux1;
                    let aux2;
                    let operator;
                    switch (true) {
                        case element.includes('>') && !element.includes('>='):
                            aux1 = element.split('>')[0];
                            aux2 = element.split('>')[1];
                            operator = '>';
                            break;
                        case element.includes('<') && !element.includes('<='):
                            aux1 = element.split('<')[0];
                            aux2 = element.split('<')[1];
                            operator = '<';
                            break;
                        case element.includes('>='):
                            aux1 = element.split('>=')[0];
                            aux2 = element.split('>=')[1];
                            operator = '>=';
                            break;
                        case element.includes('<='):
                            aux1 = element.split('<=')[0];
                            aux2 = element.split('<=')[1];
                            operator = '<=';
                            break;
                        case element.includes('=') && !element.includes('!='):
                            aux1 = element.split('=')[0];
                            aux2 = element.split('=')[1];
                            operator = '=';
                            break;
                        case element.includes('!='):
                            aux1 = element.split('!=')[0];
                            aux2 = element.split('!=')[1];
                            operator = '!=';
                            break;
                        case element.includes('<>'):
                            aux1 = element.split('<>')[0];
                            aux2 = element.split('<>')[1];
                            operator = '<>';
                            break;
                        case element.includes('NLIKE'):
                            aux1 = element.split('NLIKE')[0];
                            aux2 = element.split('NLIKE')[1];
                            operator = 'NLIKE';
                            break;
                        case element.includes('NILIKE'):
                            aux1 = element.split('NILIKE')[0];
                            aux2 = element.split('NILIKE')[1];
                            operator = 'NILIKE';
                            break;
                        case element.includes('ILIKE'):
                            aux1 = element.split('ILIKE')[0];
                            aux2 = element.split('ILIKE')[1];
                            operator = 'ILIKE';
                            break;
                        case element.includes('LIKE'):
                            aux1 = element.split('LIKE')[0];
                            aux2 = element.split('LIKE')[1];
                            operator = 'LIKE';
                            break;
                        case element.includes('NIREGEXP'):
                            aux1 = element.split('NIREGEXP')[0];
                            aux2 = element.split('NIREGEXP')[1];
                            operator = 'NIREGEXP';
                            break;
                        case element.includes('NREGEXP'):
                            aux1 = element.split('NREGEXP')[0];
                            aux2 = element.split('NREGEXP')[1];
                            operator = 'NREGEXP';
                            break;
                        case element.includes('IREGEXP'):
                            aux1 = element.split('IREGEXP')[0];
                            aux2 = element.split('IREGEXP')[1];
                            operator = 'IREGEXP';
                            break;
                        case element.includes('REGEXP'):
                            aux1 = element.split('REGEXP')[0];
                            aux2 = element.split('REGEXP')[1];
                            operator = 'REGEXP';
                            break;
                        case element.includes('NOTIN'):
                            aux1 = element.split('NOTIN')[0];
                            aux2 = element.split('NOTIN')[1];
                            operator = 'NOTIN';
                            break;
                        case element.includes('IN'):
                            aux1 = element.split('IN')[0];
                            aux2 = element.split('IN')[1];
                            operator = 'IN';
                            break;

                        default:
                            break;
                    }

                    if (aux1.includes('(')) {
                        aux1 = aux1.replace('(', '')
                            // CHECK IF aux1 IS A STRING OR A NUMBER
                        if (isNaN(aux1)) {
                            filter.push({
                                "type": "operator",
                                "value": '('
                            });
                            filter.push({
                                "type": "query",
                                "field": aux1,
                                "operator": operator,
                                "value": aux2
                            });
                            return;
                        } else {
                            filter.push({
                                "type": "operator",
                                "value": '('
                            });
                            filter.push({
                                "type": "query",
                                "field": aux2,
                                "operator": operator,
                                "value": aux1
                            });
                            return;
                        }
                    }

                    if (aux2.includes(')')) {
                        aux2 = aux2.replace(')', '')
                            // CHECK IF aux1 IS A STRING OR A NUMBER
                        if (isNaN(aux1)) {
                            filter.push({
                                "type": "query",
                                "field": aux1,
                                "operator": operator,
                                "value": aux2
                            });
                            filter.push({
                                "type": "operator",
                                "value": ')'
                            });
                            return;
                        } else {
                            filter.push({
                                "type": "query",
                                "field": aux2,
                                "operator": operator,
                                "value": aux1
                            });
                            filter.push({
                                "type": "operator",
                                "value": ')'
                            });
                            return;
                        }
                    }

                    // CHECK IF aux1 IS A STRING OR A NUMBER
                    if (isNaN(aux1)) {
                        filter.push({
                            "type": "query",
                            "field": aux1,
                            "operator": operator,
                            "value": aux2
                        });
                    } else {
                        filter.push({
                            "type": "query",
                            "field": aux2,
                            "operator": operator,
                            "value": aux1
                        });
                    }


                } else {
                    // IF element DOES NOT CONTAIN AN ARITHMETIC OPERATOR, IS AN OPERATOR (AND, OR, NOT)
                    let aux_element
                    switch (element) {
                        case '&':
                        case '&&':
                            aux_element = 'AND';    
                            break;
                        case '|':
                        case '||':
                            aux_element = 'OR';
                            break;
                        case '!':
                        case '!!':
                            aux_element = 'NOT';
                            break;
                        default:
                            aux_element = element;
                            break;
                    }
                    filter.push({
                        "type": "operator",
                        "value": aux_element
                    });
                }
            });

            console.log("FILTER: ", filter);
            return filter;
        },

        /* -------------------------------------------------------------------------------------------------------------------- */
        /* -------------------------------------------------------- AUX ------------------------------------------------------- */
        /* -------------------------------------------------------------------------------------------------------------------- */
        checkIfAnyFieldIsSeverityOrCriticality() {
            if (this.selected_main_field) {
                console.log("MAIN FIELD: ", this.selected_main_field.name);
                if (this.selected_main_field.name === 'severity' || this.selected_main_field.name === 'criticality') {
                    this.showPalettes = false;
                }
            }
            if (this.selected_xaxis_field) {
                console.log("X AXIS FIELD: ", this.selected_xaxis_field.name);
                if (this.selected_xaxis_field.name === 'severity' || this.selected_xaxis_field.name === 'criticality') {
                    this.showPalettes = false;
                }
            }
            if (this.selected_slices_field) {
                console.log("SLICES FIELD: ", this.selected_slices_field.name);
                if (this.selected_slices_field.name === 'severity' || this.selected_slices_field.name === 'criticality') {
                    this.showPalettes = false;
                    console.log("SHOW PALETTES: ", this.showPalettes);
                }
            }
            this.showPalettes = true;
        },
        setSlicesInPreview(slices_boolean) {
            /*
            if(!slices_boolean){
                this.tempChartAuxData.chartData.datasets[0].data.datasets = [
                    {
                        type: 'bar',
                        label: 'Dataset 1',
                        data: [50, 25, 12, 48, 90, 76, 42]
                    },
                    {
                        type: 'bar',
                        label: 'Dataset 2',
                        data: [21, 84, 24, 75, 37, 65, 34]
                    },
                    {
                        type: 'bar',
                        label: 'Dataset 3',
                        data: [41, 52, 24, 74, 23, 21, 32]
                    }
                ]
                console.log("SLICES IN PREVIEW: ", this.tempChartAuxData.chartData.datasets[0].data);
            }else{
                console.log("SLICES IN PREVIEW: ", this.tempChartAuxData.chartData.datasets[0].data);
            }
            */
        },
        getRandomDate() {
            let start = new Date(2022, 0, 1);
            let end = new Date();
            // format date to YYYY-MM-DD
            return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString().split('T')[0];
        },
        generateRandomData(n) {
            let data = [];
            for (let i = 0; i < n; i++) {
                data.push(Math.floor(Math.random() * 1000));
            }
            return data;
        },
        getRandomColorsFromPalette(n, palette) {
            console.log("PALETTE: ", palette);
            // get n random colors from palette (array of colors) without repetition
            if (n > palette.length) {
                n = palette.length;
            }
            let colors = [];
            for (let i = 0; i < n; i++) {
                let randomColor = palette[Math.floor(Math.random() * palette.length)];
                if (!colors.includes(randomColor)) {
                    colors.push(randomColor);
                } else {
                    i--;
                }
            }
            return colors;
        },
        generateLabel(n, field) {
            console.log("SELECTED SLICES FIELD: ", field);
            if (field.name == 'criticality' || field.name == 'severity') {
                this.tempChartAuxData.chartData.datasets[0].backgroundColor = ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32']
                this.tempChartAuxData.chartData.datasets[0].borderColor = ['#aeb5c1', '#6dc193', '#f7b34c', '#ef6154', '#b83c32']
                return ['Informational', 'Low', 'Medium', 'High', 'Critical'];
            }
            let labels = [];
            for (let i = 0; i < n; i++) {
                labels.push(field.name + ' ' + i);
            }
            return labels;
        },
        updateTempVisualization(option) {
            this.tempChartAuxData.chartData.datasets[0].data = this.generateRandomData(5);
            let colors = this.getRandomColorsFromPalette(5, this.selectedPalette.colors);

            switch (option) {
                case 'color_palette':
                    this.tempChartAuxData.chartData.datasets[0].backgroundColor = colors;
                    this.tempChartAuxData.chartData.datasets[0].borderColor = colors;
                    break;
                case 'date_histogram':
                    // array of 4 random dates
                    let dates = [];
                    for (let i = 0; i < 5; i++) {
                        dates.push(this.getRandomDate());
                    }
                    this.tempChartAuxData.chartData.labels = dates;
                    break;
                case 'change_model':
                    this.tempChartAuxData.chartData.datasets[0].label = this.selected_main_model;
                    break;

                case 'terms':
                    if (this.selectedChartType == 'pie' || this.selectedChartType == 'doughnut')
                        this.tempChartAuxData.chartData.labels = this.generateLabel(5, this.selected_slices_field);
                    if (this.selectedChartType == 'bar' || this.selectedChartType == 'line')
                        this.tempChartAuxData.chartData.labels = this.generateLabel(5, this.selected_xaxis_field);
                    break;
                case 'order_by':
                    // order this.tempChartAuxData.chartData.datasets[0].data by this.selected_order_by_field
                    if (this.selected_order_by.value == 'asc')
                        this.tempChartAuxData.chartData.datasets[0].data.sort((a, b) => a - b);
                    else
                        this.tempChartAuxData.chartData.datasets[0].data.sort((a, b) => b - a);
                    break;
                default:
                    break;
            }

            /*
            tempChartAuxData: {
                chartData: {
                    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
                    datasets: [{
                        label: 'Sales',
                        data: [540, 325, 702, 620],
                        backgroundColor: ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'],
                        borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
                        borderWidth: 1
                    }]
                }
            }
            */
        },
        clearFieldsSelectors() {
            this.selected_main_field = null;
            this.selected_field = null;
            this.selected_slice = {
                name: '',
                value: ''
            };
            this.selected_x_axis = {
                name: '',
                value: ''
            };
        },
        getSources() {
            $api.getSources().then((response) => {
                this.sources_and_models = response;
                this.main_models = response.models.concat(response.sources);
                this.selected_main_model = this.main_models[1];
                this.getMainFields(this.selected_main_model, false);
                this.selected_main_field = this.main_fields[0];
                console.log('main_fields: ', this.main_fields);
                this.clearFieldsSelectors();
                console.log('=================================================')
                console.log(this.main_models);


                // sort main_models alphabetically
                this.main_models.sort();

                
            }).catch((error) => {
                console.log('[-] Error on getSources: ', error);
            });
        },
        isInModelsOrSources(source) {
            for (let i = 0; i < this.sources_and_models.models.length; i++) {
                if (this.sources_and_models.models[i] == source) {
                    return 'model'
                }
            }

            return 'source';

        },
        getMainFields(source, filter_by_date_type) {
            //console.log('SOURCE: ', source);
            //console.log('SOURCES AND MODELS: ', this.sources_and_models);
            let is_source = false;

            // loop this.sources_and_models
            for (let i = 0; i < this.sources_and_models.sources.length; i++) {
                // if source is a model
                if (this.sources_and_models.sources[i] == source) {
                    //console.log('SOURCE IS A SOURCE');
                    is_source = true;
                }
            }

            for (let i = 0; i < this.sources_and_models.models.length; i++) {
                // if source is a model
                if (this.sources_and_models.models[i] == source) {
                    //console.log('SOURCE IS A MODEL');
                    is_source = false;
                }
            }

            if (is_source) {
                $api.getSourceFields(source).then((response) => {
                    // JSON TO ARRAY
                    let aux = [];
                    for (let key in response) {
                        aux.push({
                            name: key,
                            value: response[key]
                        });
                    }
                    if (!filter_by_date_type) {
                        this.main_fields = aux;
                        this.slices_fields = aux;
                        this.fields = aux;
                        this.options = aux;
                        this.terminalOptions = aux;
                        this.options.push({
                            name: '(',
                            value: 'PARENTESIS_OPEN'
                        });
                    } else {
                        this.fields = aux.filter((field) => {
                            return field.value == 'date';
                        });
                    }
                }).catch((error) => {
                    console.log('[-] Error on getSourceFields: ', error);
                });
            } else {
                $api.getModelFields(source).then((response) => {
                    // JSON TO ARRAY
                    let aux = [];
                    for (let key in response) {
                        aux.push({
                            name: key,
                            value: response[key]
                        });
                    }
                    if (!filter_by_date_type) {
                        this.main_fields = aux;
                        this.slices_fields = aux;
                        this.fields = aux;
                        this.options = aux;
                        this.terminalOptions = aux;
                        this.options.push({
                            name: '(',
                            value: 'PARENTESIS_OPEN'
                        });
                    } else {
                        this.fields = aux.filter((field) => {
                            return field.value == 'DATETIME';
                        });
                    }
                }).catch((error) => {
                    console.log('[-] Error on getModelFields: ', error);
                });
            }

        },
        clearVariables() {
            this.isAnUpdate = false;
            this.toggleLibrary = false;
            this.toggleChart = false;
            this.selected_main_model = this.main_models[0];
            this.selected_metric = {
                name: '',
                value: ''
            };
            this.selected_main_field = '';
            this.selected_slice = {
                name: '',
                value: ''
            };
            this.selected_field = ''
            this.selected_interval = {
                name: '',
                value: ''
            };
            this.selected_x_axis = {
                name: '',
                value: ''
            };
            this.slices_boolean = false;

        },

        prettifyString(string) {
            return string.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
        },

        addNewData(type) { /* DEPRECATED */
            switch (type) {
                case 'line':
                    this.tempChartAuxData.chartData.datasets.push({
                        label: 'New Dataset',
                        data: [Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1],
                        fill: false,
                        borderColor: this.selectedPalette.colors[Math.floor(Math.random() * this.selectedPalette.colors.length)],
                        tension: .4
                    })
                    if (this.tempChartAuxData.chartData.labels.length == 0) {
                        console.log("AÑADIENDO DATA...", this.tempChartAuxData);
                        this.tempChartAuxData.chartData.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
                    }
                    break;
                case 'bar':
                    this.tempChartAuxData.chartData.datasets.push({
                        label: 'New Dataset',
                        data: [Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1],
                        backgroundColor: this.selectedPalette.colors[Math.floor(Math.random() * this.selectedPalette.colors.length)],
                    })
                    if (this.tempChartAuxData.chartData.labels.length == 0) {
                        console.log("AÑADIENDO DATA...", this.tempChartAuxData);
                        this.tempChartAuxData.chartData.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
                    }
                    break;
                case 'pie':
                    this.tempChartAuxData.chartData.datasets.push({
                        label: 'New Dataset',
                        data: [Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1, Math.floor(Math.random() * 100) + 1],
                        backgroundColor: this.selectedPalette.colors
                    })
                    if (this.tempChartAuxData.chartData.labels.length == 0) {
                        console.log("AÑADIENDO DATA...", this.tempChartAuxData);
                        this.tempChartAuxData.chartData.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
                    }
                    break;
                default:
                    break;
            }
        },
        onlyValidSumAndAvgFields() {
            let valid_fields = [];

            // loop main_fields
            for (let i = 0; i < this.main_fields.length; i++) {
                console.log("Field: ", this.main_fields[i].value);
                // check if field contains a value that can be summed or averaged
                if (this.main_fields[i].value == 'INTEGER' || this.main_fields[i].value == 'FLOAT' || this.main_fields[i].value == 'DOUBLE' || this.main_fields[i].value == 'DECIMAL') {
                    valid_fields.push(this.main_fields[i]);
                    console.log("-> Valid field: ", this.main_fields[i].name);
                }
            }
            console.log("VALID FIELDS: ", valid_fields);
            return valid_fields;
        },
    },
    async mounted() {
        const userStore = useUserStore();
        const userObject = await userStore.getUser();
        this.user = userObject;

        this.getSources();

        this.options = this.option_rules_fields;
        this.terminalOptions = this.option_rules_fields;

        TerminalService.on('command', this.commandHandler);

        // 1. CHECK IF DASHBOARD ID IS IN THE URL
        if (this.$route.query.id) {
            this.dashboardCreated = true;
            this.dashboard_id = this.$route.query.id;
            // 1.1 GET DASHBOARD DATA
            $api.getOneDashboard(this.$route.query.id, false).then((response) => {
                this.dashboard_name = response.name;
                this.dashboard_desc = response.description;
            }).catch(error => {
                console.log("[-] Error in getOneDashboard", error);
            });

            // 2. IF YES, GET ALL VISUALIZATION FOR THIS DASHBOARD ID
            $api.getOneVisualizationDashboard(this.$route.query.id).then((response) => {
                this.data = response;
                console.log("DASHBOARDS: ", this.data);
                // 3. LOOP VISUALIZATION AND GET VISUALIZATION DATA
                for (let i = 0; i < this.data.length; i++) {
                    $api.getOneVisualization(this.data[i].visualization_id).then(async(response) => {
                        this.tempChart = response;
                        this.tempChart.h = this.data[i].h;
                        this.tempChart.w = this.data[i].w;
                        this.tempChart.x = this.data[i].x;
                        this.tempChart.y = this.data[i].y;
                        this.tempChart.i = this.data[i].visualization_id;
                        console.log("VISUALIZATION [" + i + "]: ", this.tempChart);

                        // find values in array tempChart.data.labels and substitute as '1' = low, '2' = medium, '3' = high, '4' = critical, '0' = info
                        this.changeLabels();

                        // 4. ADD VISUALIZATION TO LAYOUT
                        await this.addChartToLayout(this.tempChart, true);

                        /*
                        for (let i = 0; i < this.layout.length; i++) {
                            await this.refreshChart(this.layout[i].id);
                        }
                        */

                    }).catch((err) => {
                        console.log(`[-] Error (getOneVisualization): ${err}`);
                    });
                }
            }).catch((err) => {
                console.log(`[-] Error (getOneVisualizationDashboard): ${err}`);
            });

        }

        this.option_rules = this.option_rules_fields;
        if (process.env.VUE_APP_ENV === 'development') {} else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            $api.getAllColorPalettes(true).then((response) => {
                this.palettes = response;
                this.selectedPalette = this.palettes[0];
                console.log("PALETTES: ", this.palettes);

            }).catch((err) => {
                console.log(`[-] Error (getAllColorPalettes): ${err}`);
            });
        }

        if (process.env.VUE_APP_ENV === 'development') {} else if (process.env.VUE_APP_ENV === 'staging' || process.env.VUE_APP_ENV === 'production') {
            $api.getAllVisualizations(false).then((response) => {
                this.existingCharts = response;
                //this.selectedExistingChart = this.existingCharts[0];
                console.log("VISUALIZATIONS: ", this.existingCharts);

            }).catch((err) => {
                console.log(`[-] Error (getAllVisualizations): ${err}`);
            });
        }


        this.stringToFilter('status =1 OR severity > 1 AND (4<status OR status>= 3)');
    },
};
export default MyShared;