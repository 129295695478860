<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="assistant-bubble text-center" @click="open = true" v-if="!open">
    <v-icon class="bubble-icon" name="bi-stars" scale="1.75" fill="#6480cd" animation="pulse" hover="true"></v-icon>
  </div>
  <div :class="maximised ? 'ai-assistan-chat chat-max' : 'ai-assistan-chat chat-min'" v-if="open">
    <div class="chat-topbar">
      <v-icon name="bi-stars" scale="0.9" fill="#000"></v-icon>{{ $t('aiAssistantChat') }}
      <div class="chat-options">
        <v-icon class="option-icon" :name="maximised ? 'bi-arrows-angle-contract' : 'bi-arrows-angle-expand'"
          scale="0.9" fill="#000" @click="(maximised = !maximised);"></v-icon>
        <v-icon class="option-icon" name="bi-x-lg" scale="0.9" fill="#000" @click="open = false"></v-icon>
      </div>
    </div>
    <div v-if="!maximised" class="chat-response-container-min">
      <ScrollPanel class="ai-scroll-container" id="scrollPanel1">
        <div class="sub-scroll-min">
          <div class="row ai-proposal-row-min">
            <div class="col-12 p-0 m-0 text-center">
              <!-- {{ $t('someInterestingPrposals') }} -->
              <p class="chat-welcome"> {{ $t('hello') + ', ' + username }}</p>
              <p class="mt-0 mb-4 p-0">{{ $t('aiAssistantWelcome')}}</p>
            </div>
            <div class="col-4 p-0" v-for="proposal in main_proposals" :is="proposal" @click="user_msg = proposal.text">
              <div class="ai-proposal m-2">
                <h6 class="ai-proposal-title">{{ $t(proposal.title) }}</h6>
                <p class="ai-proposal-text">{{ proposal.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="msg-container-min col-12 p-0">
          <div class="row m-0 p-0" v-for="msg in actual_msg_array">
            <div class="col-12 p-0 m-0">
              <div v-if="msg.type === 'bot'" class="ai-msg-bot m-2">
                <p class="msg-title">{{ $t('aiAssistantChat') }}<span class="msg-time"> &nbsp;{{ msg.time }}</span></p>
                <p>{{ msg.msg }}</p>
              </div>
              <div v-if="msg.type === 'user'" class="ai-msg-user m-2">
                <p class="msg-title"><span class="msg-time">{{ msg.time }}</span></p>
                <p>{{ msg.msg }}</p>
              </div>
              <div v-if="msg.type === 'loading'" class="ai-msg-loading m-2">
                <v-icon name="bi-arrow-clockwise" scale="1" animation="spin" speed="1"></v-icon>
              </div>
            </div>
          </div>
        </div>
      </ScrollPanel>
    </div>
    <div v-else class="chat-response-container-max row m-0">
      <div class="col-2 ai-assistant-chats p-0 m-0">
        <div v-for="(conversation) in conversations_array" :key="conversation"
          @click="actual_msg_array = conversation.msg_array; actual_msg_id = conversation.id">
          <div :class="(conversation.id == actual_msg_id) ? 'conversation-button-active' : 'conversation-button'">
            <p class="chat-title">{{ $t('Chat') + ' ' + conversation.id }} <v-icon class="delete-chat" name="bi-trash"
                scale="1" @click="deleteChat(conversation.id)"></v-icon></p>
            <p class="chat-last-msg">[...] {{ wrapString(conversation.msg_array[conversation.msg_array.length -
              1].msg, 120) }}</p>
          </div>
        </div>
        <div class="new-chat-button" @click="newChat">
          <v-icon name="bi-plus" scale="1.5" fill="#000"></v-icon>
        </div>
      </div>
      <ScrollPanel class="ai-scroll-container col-10 p-0 m-0" id="scrollPanel2">
        <div class="sub-scroll-max">
          <div class="row ai-proposal-row-max">
            <div class="col-12 p-0 m-0 text-center">
              <!-- {{ $t('someInterestingPrposals') }} -->
              <p class="chat-welcome"> {{ $t('hello') + ', ' + username }}</p>
              <p class="mt-0 mb-4 p-0">{{ $t('aiAssistantWelcome')}}</p>
            </div>
            <div class="col-4 p-0" v-for="proposal in main_proposals" :is="proposal" @click="user_msg = proposal.text">
              <div class="ai-proposal m-2">
                <h6 class="ai-proposal-title">{{ $t(proposal.title) }}</h6>
                <p class="ai-proposal-text">{{ proposal.text }}</p>
              </div>
            </div>
          </div>
          <div class="msg-container-max col-12 p-0">
            <div class="row m-0 p-0" v-for="msg in actual_msg_array">
              <div class="col-12 p-0 m-0">
                <div v-if="msg.type === 'bot'" class="ai-msg-bot m-2">
                  <p class="msg-title">{{ $t('aiAssistantChat') }}<span class="msg-time"> &nbsp;{{ msg.time }}</span>
                  </p>
                  <p>{{ msg.msg }}</p>
                </div>
                <div v-if="msg.type === 'user'" class="ai-msg-user m-2">
                  <p class="msg-title"><span class="msg-time">{{ msg.time }}</span></p>
                  <p>{{ msg.msg }}</p>
                </div>
                <div v-if="msg.type === 'loading'" class="ai-msg-loading m-2">
                  <v-icon name="bi-arrow-clockwise" scale="1" animation="spin" speed="1"></v-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollPanel>
    </div>
    <div class="input-container row">
      <div v-if="maximised" class="row m-0 p-0">
        <div class="col-2 p-0 m-0"></div>
        <div class="col-9 p-0 m-0">
          <input type="text" class="form-control" :placeholder="$t('typeMsg')" v-model="user_msg"
            v-on:keyup.enter="addMessage(user_msg, 'user')" />
        </div>
        <div class="col-1 p-0 m-0">
          <button :class="maximised ? 'snd-msg-button-max' : 'snd-msg-button-min'"
            @click="addMessage(user_msg, 'user')">
            <v-icon class="button-icon" name="bi-send" scale="1.2" fill="#fff"></v-icon>
          </button>
        </div>
      </div>
      <div v-else class="row m-0 p-0">
        <div class="col-11 p-0 m-0">
          <input type="text" class="form-control" :placeholder="$t('typeMsg')" v-model="user_msg"
            v-on:keyup.enter="addMessage(user_msg, 'user')" />
        </div>
        <div class="col-1 p-0 m-0">
          <button :class="maximised ? 'snd-msg-button-max' : 'snd-msg-button-min'"
            @click="addMessage(user_msg, 'user')">
            <v-icon class="button-icon" name="bi-send" scale="1.2" fill="#fff"></v-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script scoped>
import Modal from '../Modals/Modal.vue'
import { nextTick } from "vue";
import ConfirmDialog from "primevue/confirmdialog";
import * as $api from "../../../api/api";

export default {
  /* Name */
  name: "AIAssistant",
  /* Data */
  data() {
    return {
      username: "",
      open: false,
      maximised: false,
      user_msg: "",
      main_proposals: [{ title: 'home', text: "Proposal 1 text" }],
      home_proposals: [
        { title: 'home', text: "Proposal 1 text" },
        { title: 'home', text: "Proposal 2 text" },
        { title: 'home', text: "Proposal 3 text" }],
      source_proposals: [
        { title: 'sources', text: "Proposal 1 text" },
        { title: 'sources', text: "Proposal 2 text" },
        { title: 'sources', text: "Proposal 3 text" }],
      listener_proposals: [
        { title: 'listeners', text: "Proposal 1 text" },
        { title: 'listeners', text: "Proposal 2 text" },
        { title: 'listeners', text: "Proposal 3 text" }],
      log_proposals: [
        { title: 'logs', text: "Proposal 1 text" },
        { title: 'logs', text: "Proposal 2 text" },
        { title: 'logs', text: "Proposal 3 text" }],
      asset_proposals: [
        { title: 'assets', text: "Proposal 1 text" },
        { title: 'assets', text: "Proposal 2 text" },
        { title: 'assets', text: "Proposal 3 text" }],
      investigation_proposals: [
        { title: 'investigations', text: "Proposal 1 text" },
        { title: 'investigations', text: "Proposal 2 text" },
        { title: 'investigations', text: "Proposal 3 text" }],
      dashboard_proposals: [
        { title: 'dashboards', text: "Proposal 1 text" },
        { title: 'dashboards', text: "Proposal 2 text" },
        { title: 'dashboards', text: "Proposal 3 text" }],
      event_proposals: [
        { title: 'events', text: "Proposal 1 text" },
        { title: 'events', text: "Proposal 2 text" },
        { title: 'events', text: "Proposal 3 text" }],
      incident_proposals: [
        { title: 'incidents', text: "Proposal 1 text" },
        { title: 'incidents', text: "Proposal 2 text" },
        { title: 'incidents', text: "Proposal 3 text" }],
      alert_proposals: [
        { title: 'alerts', text: "Proposal 1 text" },
        { title: 'alerts', text: "Proposal 2 text" },
        { title: 'alerts', text: "Proposal 3 text" }],
      correlation_proposals: [
        { title: 'correlation', text: "Proposal 1 text" },
        { title: 'correlation', text: "Proposal 2 text" },
        { title: 'correlation', text: "Proposal 3 text" }],
      detection_proposals: [
        { title: 'detection', text: "Proposal 1 text" },
        { title: 'detection', text: "Proposal 2 text" },
        { title: 'detection', text: "Proposal 3 text" }],
      user_roles_proposals: [
        { title: 'userRoles', text: "Proposal 1 text" },
        { title: 'userRoles', text: "Proposal 2 text" },
        { title: 'userRoles', text: "Proposal 3 text" }],
      organization_proposals: [
        { title: 'organizations', text: "Proposal 1 text" },
        { title: 'organizations', text: "Proposal 2 text" },
        { title: 'organizations', text: "Proposal 3 text" }],

      conversations_array: [
        {
          id: 1,
          msg_array: [
            { msg: "Hola, ¿en qué puedo ayudarte?", type: "bot", time: "12:00" },
          ],
        },
        {
          id: 2,
          msg_array: [
            { msg: "Esta es otra conversación", type: "bot", time: "13:45" },
            { msg: "Ah si! Vamos a probar como funciona", type: "user", time: "13:46" },
            { msg: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.", type: "bot", time: "13:48" },
          ],
        },
      ],
      actual_msg_array: [],
      actual_msg_id: 1
    }
  },
  /* Components */
  components: {
    Modal,
    ConfirmDialog
  },
  /* Props */
  props: {},
  /* Methods */
  methods: {
    wrapString(string, max) {
      return string.length > max ? string.substring(0, max) + "..." : string;
    },
    async addMessage(msg, type) {
      if (msg === "") return;

      this.user_msg = "";
      this.actual_msg_array.push({ msg: msg, type: type });
      this.scrollToBottom()
      // TO DO: add logic to get response from AI
      this.actual_msg_array.push({ msg: "loading...", type: "loading" });

      // Wait 1 second
      await new Promise(resolve => setTimeout(resolve, 2000));

      let time = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      this.actual_msg_array.pop();
      this.actual_msg_array.push({ msg: "Estamos haciendo pruebas, de momento solo puedo responderte esto. ", type: "bot", time: time });

      // Scroll to bottom
      this.scrollToBottom()
    },
    async scrollToBottom() {
      // Scroll to bottom
      let elements = document.getElementsByClassName('p-scrollpanel-content');
      this.$nextTick(async () => {
        for (let i = 0; i < elements.length; i++) {
          let element = elements[i];
          //console.log(element);

          // get parent's parent element
          let parent = element.parentElement.parentElement;
          //console.log(parent);

          if (parent.id == "scrollPanel1" || parent.id == "scrollPanel2") {
            element.scrollTop = element.scrollHeight;
            await nextTick();
            element.scrollTo(0, element.scrollHeight + 99999);
          }
          i++;
        }
      });
    },
    setOptions(path) {
      switch (path) {
        case "/":
          this.open = false;
          break;
        case "/home":
          this.main_proposals = this.home_proposals;
          break;
        case "/source":
          this.main_proposals = this.source_proposals;
          break;
        case "/listener":
          this.main_proposals = this.listener_proposals;
          break;
        case "/log":
          this.main_proposals = this.log_proposals;
          break;
        case "/asset":
          this.main_proposals = this.asset_proposals;
          break;
        case "/investigation":
          this.main_proposals = this.investigation_proposals;
          break;
        case "/dashboard":
          this.main_proposals = this.dashboard_proposals;
          break;
        case "/event":
          this.main_proposals = this.event_proposals;
          break;
        case "/incident":
          this.main_proposals = this.incident_proposals;
          break;
        case "/alert":
          this.main_proposals = this.alert_proposals;
          break;
        case "/correlation-rule":
          this.main_proposals = this.correlation_proposals;
          break;
        case "/detection-rule":
          this.main_proposals = this.detection_proposals;
          break;
        case "/manage-roles":
          this.main_proposals = this.user_roles_proposals;
          break;
        case "/organization":
          this.main_proposals = this.organization_proposals;
          break;
        default:
          //this.open = false;
          break;
      }
    },
    newChat() {
      this.conversations_array.push({
        id: this.conversations_array.length + 1,
        msg_array: [
          { msg: "Hola, ¿en qué puedo ayudarte?", type: "bot" },
        ],
      });
      this.actual_msg_array = this.conversations_array[this.conversations_array.length - 1].msg_array;
      this.actual_msg_id = this.conversations_array[this.conversations_array.length - 1].id;
      this.scrollToBottom()
    },
    deleteChat(id) {
      this.$confirm.require({
        message: this.$t('deleteConfirmationAlert'),
        header: this.$t('deleteConfirmation'),
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: () => {
          this.conversations_array = this.conversations_array.filter((conversation) => conversation.id !== id);
          this.actual_msg_array = this.conversations_array[0].msg_array;
          this.actual_msg_id = this.conversations_array[0].id;
          this.scrollToBottom()
        },
        reject: () => {
          //this.$toast.add({severity:'error', summary:this.$t('rejected'), detail:this.$t('rejectedAlert'), life: 3000});
        }
      });
    },
  },
  /* Watch */
  watch: {
    $route(to, from) {
      // react to route changes...
      this.setOptions(to.path);
    }
  },
  async mounted() {
    //this.openModal();
    this.actual_msg_array = this.conversations_array[0].msg_array;
    this.actual_msg_id = this.conversations_array[0].id;
    this.scrollToBottom()
    this.setOptions(this.$route.path);

    $api.getMe(true).then((response) => {
      this.username = response.full_name;
      //console.log("ME: ", this.user);
    }).catch((err) => {
      console.log(`[-] Error (getMe): ${err}`);
    });
  },
  /* Emits */
  emits: ["emits"],
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->

<style scoped>
.assistant-bubble {
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  background-color: aliceblue;
  z-index: 999;
  cursor: pointer;
  padding: 1rem;
}

.assistant-bubble:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.5) 0px 1px 3px -1px;
}

.bubble-icon {
  vertical-align: middle;
  margin: auto;
}

.ai-assistan-chat {
  background-color: #f5f7f6;
  border-radius: 0.25rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 0.5s;
  z-index: 9999;
}

.chat-min {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 35rem;
  height: 35rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
}

.chat-max {
  position: fixed;
  bottom: 50%;
  right: 50%;
  width: 80%;
  height: 95%;
  transform: translate(50%, 50%);
  z-index: 999;
}

.chat-options {
  float: right;
}

.option-icon {
  cursor: pointer;
  margin-left: 0.5rem;
}

.chat-topbar {
  height: 2.5rem;
  background-color: #f1f1f1;
  border-bottom: 1px solid #e0e0e0;
  padding: .5rem 0.75rem;
}

.input-container {
  background-color: #f1f1f1;
  border-top: 1px solid #e0e0e0;
  position: absolute;
  bottom: 0;
  padding: 0.5rem 0.75rem !important;
  margin: 0;
  width: 100%;
}

.input-container input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 500px;
}

.input-container input:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
}

.snd-msg-button-min {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  background-color: #ef6154;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 0.5rem;
}

.snd-msg-button-min:hover {
  background-color: #f44336;
}

.snd-msg-button-max {
  width: 95%;
  height: 2.25rem;
  border-radius: 500px;
  background-color: #ef6154;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 0.5rem;
  color: #fff;
}

.snd-msg-button-max:hover {
  background-color: #f44336;
}

.ai-proposal-row-min {
  margin-top: 0.25rem !important;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
  margin: 1rem 0.5rem 0 0.5rem;
}

.ai-proposal-row-max {
  margin: 0;
  margin-top: 1rem;
  padding: 0.25rem 1rem;
  border-radius: 0.25rem;
}

.button-icon {
  margin-left: -3px;
  margin-top: 2px;
}

.ai-scroll-container {
  height: 100%;
  overflow-y: auto;
}

.chat-response-container-min {
  height: 28rem;
}

.chat-response-container-max {
  height: 50.25rem;
}

.ai-proposal {
  border-radius: 0.25rem;
  padding: .25rem 0.5rem;
  height: 5.5rem;
  cursor: pointer;
  color: #000000;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.ai-proposal:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.ai-proposal-title {
  font-size: 0.8rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #050505;
}

.ai-proposal-text {
  font-size: 0.8rem;
  margin: 0;
}

.ai-assistant-chats {
  background-color: #f1f1f1;
  border-right: 1px solid #e0e0e0;
  margin: 0 !important;
  z-index: 99;
}

.sub-scroll-max {
  margin: 2rem 8rem 0 10rem;
}

.sub-scroll-min {
  margin: 0.5rem 0.5rem 0 0.5rem;
}

.msg-container-min {
  margin: 0.5rem;
}

.msg-container-max {
  margin: 2rem 0 0 0;
}

.ai-msg-bot {
  /*background-color: #f1f1f1;*/
  width: 80%;
  border-radius: 500px 500px 500px 0rem;
  padding: 0.25rem 0.75rem;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;*/
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.msg-title {
  font-size: 0.8rem;
  margin: 0;
  font-weight: bold;
}

.msg-time {
  font-size: 0.7rem;
  color: #7a7a7a;
}

.ai-msg-loading {
  /*background-color: #f1f1f1;*/
  width: 80%;
  border-radius: 500px 500px 500px 0rem;
  padding: 0.25rem 0.75rem;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;*/
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.ai-msg-user {
  background-color: #ecf3fa;
  /* #ef6154d4 */
  width: 80%;
  color: black;
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;*/
  margin: 0.5rem 0;
  float: right;
  margin-right: 1.5rem !important;
  font-size: 0.9rem;
}

.conversation-button {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #f1f1f1;
  border-bottom: 1px solid #e0e0e0;
}

.conversation-button-active {
  cursor: pointer;
  padding: 0.5rem;
  background-color: #ffffff;
  color: black;
  border-bottom: 1px solid #e0e0e0;
}

.chat-title {
  font-size: 0.9rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #050505;
  font-weight: bold;
}

.chat-last-msg {
  font-size: 0.8rem;
  margin: 0;
}

.new-chat-button {
  text-align: center;
  cursor: pointer;
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  background-color: #dbdbdb;
  border: 1px solid #e0e0e0;
}

.delete-chat {
  cursor: pointer;
  float: right;
  margin-top: -4px;
  margin-right: -4px;
}

.chat-welcome {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  background: -webkit-linear-gradient(left,#ffe605, #f44336, #546bef );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 5rem;
}
</style>
