<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="mitre-technique">
        <div class="technique-div">
            <div class="row p-0">
                <div class="col-11">
                    <span @click="open = !open" v-if="technique.subtechniques.length > 0" class="technique-open mr-0 ml-2">
                        <v-icon :name="open ? 'bi-chevron-up' : 'bi-chevron-down'" scale="0.8"></v-icon>
                    </span>
                    <span class="ml-1 technique-name-1" @click="openRoute(technique.url)">{{ technique.name }}</span>
                    <span class="technique-count" v-if="technique.subtechniques.length > 0"> ({{
                        technique.subtechniques.length + ' ' + $t('subtechniques') }})</span>
                </div>
                <div class="col-1">
                    <span class="more-button" @click="desc_open = !desc_open">{{ !desc_open ? $t('more') + '...' :
                        $t('less') + '...'}}</span>
                </div>
            </div>
            <div class="row desc-row m-0" style="width: 100%;" v-if="desc_open">
                <p class="tactic-description" @click="desc_open = !desc_open">{{ technique.description }}</p>
            </div>
        </div>

        <Subtechnique class="mitre-subtechnique" v-if="technique.subtechniques && open" v-for="subtechnique in technique.subtechniques" :key="subtechnique.id" :subtechnique="subtechnique"></Subtechnique>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script scoped>
import Subtechnique from "./Subtechnique.vue";
export default {
    name: "Technique",
    data() {
        return {
            open: true,
            desc_open: false
        };
    },
    components: {
        Subtechnique
    },
    methods: {
        wrapString(str, maxLen) {
            if (str.length > maxLen) {
                return str.substring(0, maxLen) + "...";
            }
            return str;
        },
        openRoute(url) {
            // Open url on new window
            window.open(url, '_blank');
        }
    },
    props: {
        technique: Object
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style scoped>
.mitre-technique{
    border-left: 1px dashed #a1a1a1;
}
.technique-name-1{
    font-size: medium;
}
.technique-name-1:hover{
    cursor: pointer;
    text-decoration: underline;
}

.more-button{
    color: #757575;
    font-size: small;
}
.more-button:hover {
    cursor: pointer;
    text-decoration: underline;
}
.tactic-description {
    font-size: small;
    color: #757575;
    font-weight: 300;
    text-align: justify;
    margin-bottom: 1rem;
    padding-right: 2.5rem;
}
.mitre-technique {
    width: 100%;
}
.name-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}
.technique-open {
    float: left !important;
}
.technique-div {
    background-color: #f7f7f7;
    border-bottom: 2px solid #222222;
    border-right: 1px solid #bbbbbb;
    border-left: 1px solid #bbbbbb;
    height: fit-content;
    padding-bottom: 0.5rem;
}
.technique-count{
    font-size: small;
    font-weight: normal;
    color: #757575;
}
</style>