<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux" ref="test_picture">
    <Sitebar></Sitebar>
    <div class="maincontainer" >
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- =================================================================== Top Buttons ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <div class="row">
        <div class="col-6 p-0"></div>
        <div class="col-6 top-config">
          <i class="pi pi-replay top-config-icon" @click="$router.go()"><span class="top-config-label">&nbsp;{{
            $t('refresh')
          }}</span></i>
          <div class="dropdown">
            <i class="pi pi-clock top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ lastHours }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" v-for="hour in hours" :key="hour" @click="setLastHours(hour)">
                {{ hour }}
              </a>
            </div>
          </div>
          <div class="dropdown" v-if="showActions">
            <i class="pi pi-cog top-config-icon dropdown-toggle" data-toggle="dropdown"><span
                class="top-config-label">&nbsp;{{ $t('actions') }}</span></i>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="width:max-content">
              <a class="dropdown-item" @click="editMode = false" v-if="editMode">
                <p><i class="pi pi-times"></i>&nbsp;{{ $t('closeEditMode') }}</p>
              </a>
              <section v-for="(action, index) in actions" :key="action" @click="action.callback()">
                <a class="dropdown-item" v-if="action.permit">
                  <p><i :class="action.icon"></i>&nbsp;{{ action.label }}</p>
                </a>
              </section>
            </div>
          </div>
          <i class="pi pi-th-large top-config-icon" @click="viewmodemenu = true"><span class="top-config-label">&nbsp;{{
            $t('viewMode')
          }}</span></i>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- =================================================================== View Menu ==================================================================== -->
      <!-- ==================================================================================================================================================== -->
      <Transition name="slide-fade">
        <div class="row viewmodemenu" v-if="viewmodemenu" @mouseleave="viewmodemenu = false">
          <!-- @mouseleave="viewmodemenu = false" -->
          <div class="col-10">
            <div class="viewmode_selector_cont" @click="viewmode = 0">
              <div class="row m-0 p-0">
                <div class="col-12 m-0 p-0">
                  <img :class="(viewmode == 0) ? 'viewmode_selector viewmode_selector_active' : 'viewmode_selector'"
                    :src="require('@/assets/tableviewmode.png')" />
                </div>
                <div class="col-12 m-0 p-0 text-center">{{ $t('tableMode') }}</div>
              </div>
            </div>
            <div class="viewmode_selector_cont" @click="viewmode = 1">
              <div class="row m-0 p-0">
                <div class="col-12 m-0 p-0">
                  <img :class="(viewmode == 1) ? 'viewmode_selector viewmode_selector_active' : 'viewmode_selector'"
                    :src="require('@/assets/dashboardviewmode1.png')" />
                </div>
                <div class="col-12 m-0 p-0 text-center">{{ $t('dashboardMode') }}</div>
              </div>
            </div>
          </div>
        </div>
      </Transition>

      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row" style="width:100%">
        <div class="col-12 ">
          <Card>
            <template #content>
              <div class="row pl-4">
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-briefcase  top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ data.length }}
                    <i class="fa-solid fa-caret-up top-tab-subicon">
                      <span class="top-tab-subicon-text">&nbsp;{{ newIncidents }}</span>
                    </i>
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('incidentsTotal') }} </p>
                </div>
                <div class="col-2" v-else>
                  <ContentLoader></ContentLoader>
                </div>
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-spinner top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ inProgressIncidents }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('incidentsInProgress') }} </p>
                </div>
                <div class="col-2" v-else>
                  <ContentLoader></ContentLoader>
                </div>
                <div class="col-2 pt-0" v-if="!loading_table">
                  <i class="fa-solid fa-circle-check top-tab-icon"></i>
                  <span class="top-tab-text">
                    {{ closedIncidents }}
                  </span>
                  <p class="top-tab-subtext mb-0">{{ $t('incidentsClosed') }} </p>
                </div>
                <div class="col-2" v-else>
                  <ContentLoader></ContentLoader>
                </div>
                <div class="col-6" v-if="!loading_table">
                  <strong class="chart-label">{{ $t('incidentsBySeverity') }} <Helper :hlp_msg="$t('elementBySeverityHelper')"></Helper></strong>
                  <div class="percentage-bar">
                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#aeb5c1; width:' + (criticality_0) + '%;'"
                      v-if="criticality_0 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_0 != -0.8 && criticality_1 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 != -0.8) || (criticality_0 != -0.8 && criticality_1 == -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8 && criticality_4 != -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#6dc193; width:' + (criticality_1) + '%;'"
                      v-if="criticality_1 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_1 != -0.8 && criticality_2 != -0.8) || (criticality_1 != -0.8 && criticality_3 != -0.8 && criticality_2 == -0.8) || (criticality_1 != -0.8 && criticality_4 != -0.8 && criticality_2 == -0.8 && criticality_3 == -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#f7b34c; width:' + (criticality_2) + '%;'"
                      v-if="criticality_2 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="(criticality_2 != -0.8 && criticality_3 != -0.8) || (criticality_2 != -0.8 && criticality_4 != -0.8 && criticality_3 == -0.8)">
                    </div>

                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#ef6154; width:' + (criticality_3) + '%;'"
                      v-if="criticality_3 != -0.8">&nbsp;
                    </div>
                    <div class="percentage-portion" :style="'display:inline-block; background:white; width:1%;'"
                      v-if="criticality_3 != -0.8 && criticality_4 != -0.8">
                    </div>
                    <div class="percentage-portion"
                      :style="'display:inline-block; background:#b83c32; width:' + (criticality_4) + '%;'"
                      v-if="criticality_4 != -0.8">&nbsp;
                    </div>
                  </div>

                  <div class="percentage-bar row" style="width: 82%;">
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#aeb5c1; font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}:
                      <strong>{{ number_of_info_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}:
                      <strong>{{ number_of_low_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}:
                      <strong>{{ number_of_medium_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}:
                      <strong>{{ number_of_high_criticality }}</strong>
                    </div>
                    <div class="pt-0 pb-0 pr-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill"
                        style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{ $t('critical')
                        }}:
                      <strong>{{ number_of_critical_criticality }}</strong>
                  </div>
                </div>
                <!--
                  <div class="percentage-bar row">
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#6dc193; font-size: 0.7em;"></i>&nbsp;{{$t('low')}}: <strong>{{number_of_low_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#f7b34c; font-size: 0.7em;"></i>&nbsp;{{$t('medium')}}: <strong>{{number_of_medium_criticality}}</strong></div>
                    <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#ef6154; font-size: 0.7em;"></i>&nbsp;{{$t('high')}}: <strong>{{number_of_high_criticality}}</strong></div>
                      <div class="pt-0 pb-0" style="display:ruby; width:auto;"><i class="pi pi-circle-fill" style="color:#b83c32; font-size: 0.7em;"></i>&nbsp;{{$t('critical')}}: <strong>{{number_of_critical_criticality}}</strong></div>
                    </div>
                    -->
                </div>
                <div class="col-6" v-else>
                  <ContentLoader height="90px" width="90%"></ContentLoader>
                </div>
              </div>
            </template>
          </Card>
        </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ===================================================================== EditMenu ===================================================================== -->
        <!-- ==================================================================================================================================================== -->
        <div v-if="editMode" class="col-12 ">
          <Card>
            <template #content>
              <div class="row pl-2">
                <p><strong>{{ $t('editOptions') }}</strong></p>
                <div class="col-12">
                  <i class="pi pi-times mr-3"
                    style="position: absolute; top:0; right:0; margin-top: -20px; cursor: pointer;"
                    @click="editMode = false"></i>
                    <div class="" style="display: block ruby;">
                      <Button class="btn-custom-save" icon="pi pi-trash" :label="$t('deleteSelected')" @click="deleteData()"></Button>
                      <section class="dropdown ml-2" style="margin-top: 8px !important; margin-bottom: 8px !important;">
                          <span class="dropdown-toggle btn-custom-save" data-toggle="dropdown" style="padding-top: 0px !important; padding-bottom: 0 !important; border-radius: 3px;">
                            <span class="" style="font-weight: normal !important; font-size: small !important;"><i class="pi-exclamation-circle" style="font-size:small"></i>&nbsp;{{$t('changeSelectedCriticality')}}</span>
                          </span>
                          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 999;">
                            <a class="dropdown-item" v-for="criticality in criticalities" :key="hour" @click="setCriticalityForSelectedItems(criticality.value)">
                              {{ criticality.label }}
                            </a>
                          </div>
                      </section>
                      <section class="dropdown ml-2" style="margin-top: 8px !important; margin-bottom: 8px !important;">
                          <span class="dropdown-toggle btn-custom-save" data-toggle="dropdown" style="padding-top: 0px !important; padding-bottom: 0 !important; border-radius: 3px;">
                            <span class="" style="font-weight: normal !important; font-size: small !important;"><i class="pi pi-stopwatch" style="font-size:small"></i>&nbsp;{{$t('changeSelectedStatus')}}</span>
                          </span>
                          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="z-index: 999;">
                            <a class="dropdown-item" v-for="status in statuses" :key="hour" @click="setStatusSelectedItems(status.status)">
                              {{ status.label }}
                            </a>
                          </div>
                      </section>
                    </div> 
                </div>
              </div>
            </template>
          </Card>
        </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Table ======================================================================= -->
        <!-- ==================================================================================================================================================== -->
        <div :class="tab_position ? 'col-8' : 'col-12 mb-0'" v-if="viewmode == 0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0 pb-3" style="height:100%;">
            <span class="p-input-icon-left" style="width:100%;">
              <InputText v-model="filters['global'].value" :placeholder="$t('searchByAsset')" class="search-input mb-2">
              </InputText>
            </span>
            <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_table">
              <ContentLoader width="100%"></ContentLoader>
            </div>
            <DataTable v-else :value="data" :paginator="true" class="p-datatable-customers" :rows="tab_position ? 15 : 6"
              :row-hover="true" v-model:selection="selectedData" dataKey="id" v-model:filters="filters"
              filter-display="menu" :selectionMode="(!editMode) ? 'single' : ''"
              paginator-template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
              :rows-per-page-options="[10, 25, 50]"
              current-page-report-template="Showing {first} to {last} of {totalRecords} entries"
              :global-filter-fields="['id', 'title', 'detection_rule', 'source', 'status', 'severity', 'last_act_date']"
              responsive-layout="scroll" @row-click="setSelectedData($event.data)">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column selection-mode="multiple" v-if="editMode"></Column>
              <Column field="title" :header="$t('title')" sortable>
                <template #body="{ data }">
                  <span @click="$router.push({ path: 'incident-details', query: { id: data.id } })"
                    class="row-name-link">
                    {{ data.title }}
                  </span>
                </template>
              </Column>
              <Column field="team" :header="$t('team')" sortable>
                <template #body="{ data }">
                  <span v-if="data.team != null">
                    {{ data.team.name }}
                  </span>
                  <span v-else > *{{ $t('unasigned') }}</span>
                </template>
              </Column>
              <Column field="status" :header="$t('status')" sortable>
                <template #body="{ data }">
                  <span v-if="prettifyString(data.status[data.status.length - 1].status) == 'New'" style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">New</span>
                  <span  v-if="prettifyString(data.status[data.status.length - 1].status) == 'Mitigated'" style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Mitigated</span>
                  <span  v-if="prettifyString(data.status[data.status.length - 1].status) == 'Partial mitigated'" style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Partial Mitigated</span>
                  <span  v-if="prettifyString(data.status[data.status.length - 1].status) == 'Active'" style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Active</span>
                  <span  v-if="prettifyString(data.status[data.status.length - 1].status) == 'Closed'" style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px;">Closed</span>     
                </template>
              </Column>
              <Column field="severity" :header="$t('severity')" sortable>
                <template #body="{ data }">
                  <span v-if="data.severity == 0"><i class="pi pi-circle-fill  criticality-filter-Info"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('informational') }}</span>
                  <span v-if="data.severity == 1"><i class="pi pi-circle-fill  criticality-filter-Low"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('low') }}</span>
                  <span v-if="data.severity == 2"><i class="pi pi-circle-fill  criticality-filter-Medium"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('medium') }}</span>
                  <span v-if="data.severity == 3"><i class="pi pi-circle-fill  criticality-filter-High"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('high') }}</span>
                  <span v-if="data.severity == 4"><i class="pi pi-circle-fill  criticality-filter-Critical"
                      style="font-size: 0.7em;"></i>&nbsp;{{ $t('critical') }}</span>
                </template>
              </Column>
              <Column field="created_at" :header="$t('createdAt')" sortable>
                <template #body="{ data }">
                  {{ formatDate(data.created_at) }}
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

        <!-- ==================================================================================================================================================== -->
        <!-- ======================================================================= Tabs ======================================================================= -->
        <!-- ==================================================================================================================================================== -->

        <div :class="tab_position ? 'col-4 pl-0' : 'col-12'" :style="tab_position ? 'height:auto;' : 'height:35vh;'"
          v-if="viewmode == 0">
          <div class="pad-helper main-table pt-2 pr-2 pl-2 m-0" style="height:100%;">
            <div class="row">
              <div class="change-pos-div">
                <img v-if="tab_position" style="float:right; cursor:pointer; height:1.25em"
                  @click="tab_position = !tab_position" :src="require('@/assets/icon-bottom.svg')">
              <img v-if="!tab_position" style="float:right; cursor:pointer; height:1.25em"
                @click="tab_position = !tab_position" :src="require('@/assets/icon-right.svg')">
              <!-- 
                  <i v-if="tab_position" class="fa-solid fa-turn-down icon-flipped" style="float:right; cursor:pointer;"
                    @click="tab_position = !tab_position"></i>
                    <i v-if="!tab_position" class="fa-solid fa-turn-up" style="float:right; cursor:pointer;"
                      @click="tab_position = !tab_position"></i>
                    -->
              </div>
            </div>
            <div class="loader text-center mt-4" style="width:100%;" v-if="loading_table">
              <ContentLoader width="100%"></ContentLoader>
            </div>
            <div v-else class="row">
              <TabView v-if="details">
                <!-- ====================================================================== Details ============================================================================== -->
                <TabPanel :header="$t('details')">
                  <div class="loader text-center mt-2 mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%;height:35em' : 'width: 100%;height:15em'"
                    class="custom" v-else>
                    <div class="sub-scroll pl-3 pr-3" :style="tab_position ? 'height:35em;' : 'height:25em;'">
                      <div class="row details-top">
                        <i class="fa-solid fa-yaveremos detail-icon"><span class="detail-name">{{ details.title
                        }}</span></i>
                        <p class="mb-0 pl-0"
                          @click="$router.push({ path: 'incident-details', query: { id: details.id } })">
                          <span class="id-prefix" style="text-decoration:none;">Incident ID:</span> <span
                            class="details-id">
                            {{ details.id }}
                          </span>
                        </p>
                      </div>
                      <div class="row details-data dropdown">
                        <div class="col-4 p-0">
                          <Card data-toggle="dropdown">
                            <template #content>
                              <i class="fa-solid fa-temperature-empty subdetail-icon">
                                <p class="subdetail-name dropdown-toggle">&nbsp;{{
                                  prettifyString(details.status[details.status.length
                                    - 1].status)
                                }}
                                </p>
                                <p class="subdetail-tag">&nbsp;{{ $t('status') }}</p>
                              </i>
                            </template>
                          </Card>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="status in statuses" :key="status"
                              @click="updateStatus(details.id, status)">
                              {{ status.label }}
                            </a>
                          </div>
                        </div>
                        <div class="col-4 p-0 dropdown">
                          <Card data-toggle="dropdown">
                            <template #content>
                              <i class="fa-solid fa-user subdetail-icon">
                                <p class="subdetail-name dropdown-toggle" v-if="details.team != null">
                                  &nbsp;{{ details.team.name }}</p>
                                <p class="subdetail-name dropdown-toggle" v-else>&nbsp;{{ $t('undefined') }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('team') }}</p>
                              </i>
                            </template>
                          </Card>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="team in teams" :key="team"
                              @click="updateTeam(details.id, team)">
                              {{ team.name }}
                            </a>
                          </div>
                        </div>
                        <div class="col-4 p-0 dropdown">
                          <Card data-toggle="dropdown">
                            <template #content>
                              <i v-if="details.severity == 1" class="fa-solid fa-shield-halved subdetail-icon"
                                style="color:#6dc193">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 1">&nbsp;{{
                                  $t('low')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                              </i>
                              <i v-if="details.severity == 2" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#f7b34c">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 2">&nbsp;{{
                                  $t('medium')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                              </i>
                              <i v-if="details.severity == 3" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#ef6154">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 3">&nbsp;{{
                                  $t('high')
                                }}</p>
                                <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                              </i>
                              <i v-if="details.severity == 4" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#b83c32">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 4">&nbsp;{{
                                  $t('critical')
                                }}
                                </p>
                                <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                              </i>
                              <i v-if="details.severity == 0" class="fa-solid fa-shield-halved subdetail-icon dropdown"
                                style="color:#aeb5c1">
                                <p class="subdetail-name dropdown-toggle" v-if="details.severity == 0">&nbsp;{{
                                  $t('informational')
                                }}
                                </p>
                                <p class="subdetail-tag">&nbsp;{{ $t('severity') }}</p>
                              </i>
                            </template>
                          </Card>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" v-for="severity in criticalities" :key="severity"
                              @click="updateSeverity(details.id, severity.value)">
                              {{ severity.label }}
                            </a>
                          </div>
                      </div>
                    </div>
                    <!--
                      <div class="row details-data save-details-data">
                        <div class="col-12 p-0 m-0" style="width:100%;">
                          <Button class="btn-custom-save mt-2" :label="$t('save')" style="float:right;"></Button>
                          </div>
                        </div>
                        -->
                      <div class="row details-data">
                        <div :class="tab_position ? 'col-12 pr-0 pl-0' : 'col-6 pr-4'">
                          <div class="row">
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('description') }}</strong></p>
                            <p class="">{{ details.description }}</p>
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('createdAt') }}</strong></p>
                            <p class="">{{ formatDate(details.created_at) }}</p>
                            <p class="mt-0 mb-0 details-title"><strong>{{ $t('status') }}</strong></p>
                            <p class="">{{ prettifyString(details.status[details.status.length - 1].status) }} - <span
                                style="color:darkgray">{{ details.status[details.status.length - 1].description
                                }}</span></p>
                          </div>
                        </div>
                        <div :class="tab_position ? 'col-12 pr-0 pl-0 pt-0' : 'col-6 pr-4'">
                          <p class="mt-0 mb-2 details-title"><strong>{{ $t('correlationRule') }}</strong></p>
                          <div class="row tab-detection-card ml-0" style="margin-left:0 !important;">
                            <p class="mt-0 mb-0"><strong>{{ details.correlation_rule.title }}</strong></p>
                            <p class="details-id mt-0 mb-0" style="font-size: 0.8em;"
                              @click="$router.push({ path: 'correlation-rule-details', query: { id: details.correlation_rule.id } })">
                              {{ details.correlation_rule.id }}</p>
                            <p class="mt-0 mb-0" style="font-size: 0.9em;">{{ details.correlation_rule.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Events =========================================================================== -->
                <TabPanel :header="$t('events')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                      <h6 v-if="!details.events" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                      </h6>
                      <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(event, index) in details.events" :key="event" :groupName="event.title"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 group-title"><strong>{{ $t('ID') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link"
                              @click="$router.push({ path: 'event-details', query: { id: event.id } })">{{ event.id }}
                            </p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ event.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                            <p v-if="event.severity == 0" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Info"
                                style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('informational') }}</span>
                            </p>
                            <p v-if="event.severity == 1" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('low') }}</span>
                            </p>
                            <p v-if="event.severity == 2" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="color:#f7b34c; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('medium') }}</span>
                            </p>
                            <p v-if="event.severity == 3" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('high') }}</span>
                            </p>
                            <p v-if="event.severity == 4" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="color:#b83c32; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('critical') }}</span>
                            </p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('createdAt') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ formatDate(event.created_at) }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ event.status[event.status.length - 1].status }} -
                              <span style="color:darkgray">{{ event.status[event.status.length - 1].description
                              }}</span>
                            </p>
                            <p class="p-0 m-0 group-data"></p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Status history =========================================================================== -->
                <TabPanel :header="$t('statusHistory')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                      <h6 v-if="!details.status" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                      </h6>
                      <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(status, index) in details.status.slice().reverse()" :key="status"
                          :groupName="prettifyString(status.status) + ' - ' + status.date"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('status') }}</strong></p>
                            <p v-if="prettifyString(status.status) == 'New'" style="background:#f28263; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">New</p>
                            <p  v-if="prettifyString(status.status) == 'Mitigated'" style="background:#f6a437; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">Mitigated</p>
                            <p  v-if="prettifyString(status.status) == 'Partial mitigated'" style="background:#ffd366; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">Partial Mitigated</p>
                            <p  v-if="prettifyString(status.status) == 'Active'" style="background:#a3dfc9; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">Active</p>
                            <p  v-if="prettifyString(status.status) == 'Closed'" style="background:#b0b0b0; color:#fff; padding: 3px; text-transform: uppercase; font-size: 0.65em; border-radius: 2px; width: auto;">Closed</p> 
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('date') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ status.date }}</p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
                <!-- ====================================================================== Affected assets =========================================================================== -->
                <TabPanel :header="$t('affectedAssets')">
                  <div class="loader text-center mt-2" style="width:100%;" v-if="loading_details">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    <p>{{ $t('loading') }}</p>
                  </div>
                  <ScrollPanel :style="tab_position ? 'width: 100%;height:32.5em' : 'width: 100%;height:15em'"
                    class="custom" v-else>
                    <div class="row pb-2 pl-1 pr-1 pt-1">
                      <h6 v-if="!details.assets" class="text-center mt-4" style="width:100%;">{{ $t('noDataAvailable') }}
                      </h6>
                      <div v-else class="aux pt-2" :style="tab_position ? 'height:55vh;' : 'height:35vh;'">
                        <OpenGroup v-for="(asset, index) in details.assets" :key="asset" :groupName="asset.name"
                          :openFirst="index == 0 ? true : false">
                          <template #body>
                            <p class="p-0 m-0 mt-2 group-title"><strong>{{ $t('id') }}</strong></p>
                            <p class="p-0 m-0 group-data row-name-link"
                              @click="$router.push({ path: 'asset-details', query: { id: asset.id } })">{{ asset.id }}
                            </p>
                            <p class="p-0 m-0 mt-2 group-title"><strong>{{ $t('description') }}</strong></p>
                            <p class="p-0 m-0 group-data">{{ asset.description }}</p>
                            <p class="p-0 m-0 mt-2 group-title "><strong>{{ $t('severity') }}</strong></p>
                            <p v-if="asset.criticality == 0" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Info"
                                style="color:rgb(109, 122, 193); font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('informational') }}</span>
                            </p>
                            <p v-if="asset.criticality == 1" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Low"
                                style="color:#6dc193; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('low') }}</span>
                            </p>
                            <p v-if="asset.criticality == 2" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Medium"
                                style="color:#f7b34c; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('medium') }}</span>
                            </p>
                            <p v-if="asset.criticality == 3" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-High"
                                style="color:#ef6154; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('high') }}</span>
                            </p>
                            <p v-if="asset.criticality == 4" class="p-0 m-0 group-data">
                              <i class="pi pi-circle-fill  criticality-filter-Critical"
                                style="color:#b83c32; font-size: 0.8em;"></i>
                              <span>&nbsp;{{ $t('critical') }}</span>
                            </p>
                          </template>
                        </OpenGroup>
                      </div>
                    </div>
                  </ScrollPanel>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
        <!-- ==================================================================================================================================================== -->
        <!-- ===================================================================== Dashboard ==================================================================== -->
        <!-- ==================================================================================================================================================== -->
        <div class="row" v-if="viewmode == 1" style="width:100%">
          <div class="col-12  mt-2 pr-0">
            <div class="row" style="margin-right:-1.5em;">
              <div class="col-6 pt-0">
                <div class="card p-2" style="height: 297px;">
                  <h5>{{ $t('IncidentsStatusByCreationTime') }}</h5>
                  <Chart type="bar" :data="stackedData" :options="stackedOptions" />
                </div>
              </div>
              <div class="col-6 pt-0" >
                <div class="card p-2" style="height: 297px;">
                  <h5>{{ $t('IncidentsStatus') }}</h5>
                  <CustomChart :data="data" :status="true"></CustomChart>
                  <hr class="mt-4">
                  <div class="row">
                    <div class="col-4">
                      <h6 class="mb-0" style="text-align:left; color:gray;">{{$t('meanTimeToAcknowledge')}}</h6>
                      <number style="font-size: 3em; font-weight:700" ref="users_number" :from="0" :to="5.2"
                        :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat" />
                        <h3 class="ml-2" style="display:inline-block">{{ $t('days') }}</h3>
                      </div>
                    <div class="col-4">
                      <h6 class="mb-0" style="text-align:left; color:gray;">{{$t('meanTimeToClose')}}</h6>
                      <number style="font-size: 3em; font-weight:700" ref="users_number" :from="0" :to="22.32"
                        :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat"/>
                        <h3 class="ml-2" style="display:inline-block">{{ $t('min') }}</h3>
                      </div>
                    <div class="col-4">
                      <h6 class="mb-0" style="text-align:left; color:gray;">{{$t('meanTimeToAcknowledge')}}</h6>
                      <number style="font-size: 3em; font-weight:700" ref="users_number" :from="0" :to="12"
                        :duration="2" easing="Power1.easeOut" class="ml-2" />
                      <h3 class="ml-2" style="display:inline-block">{{ $t('days') }}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3">
                <div class="card p-2">
                  <h5>{{ $t('topCorrelationRules') }}</h5>
                  <Chart type="pie" :data="pieChartData1" :options="pieChartOptions" height="250"/>
                </div>
              </div>
              <div class="col-3">
                <div class="card p-2">
                  <h5>{{ $t('topSources') }}</h5>
                  <Chart type="pie" :data="pieChartData2" :options="pieChartOptions" height="250"/>
                </div>
              </div>
              <div class="col-6">
                <div class="card p-2">
                  <h5>{{ $t('topAssetsIncidents') }}</h5>
                  <Chart type="bar" :data="barData" :options="lineChartOptions" height="250" width="750"/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--============================================================================== createData ==============================================================================-->
    <Modal ref="createData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('createNewIncident') }}</h5>
            {{ $t('or') }}
            <h5 class="mt-2"><FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000" @uploader="onUploadOne()" customUpload /><Helper :hlp_msg="$t('importFileHelper')"></Helper>
                </h5>
          </div>
        </div>
        <div class="row mt-4">
          <Steps :model="items" :readonly="false" class="mt-4">
            <template #item="{ item }">
              <a :style="(item.step == step) ? 'color:#ec635f; font-weight: 700; cursor:pointer;' : 'color:black; cursor:pointer;'"
                @click="step = item.step">{{ item.label }}</a>
            </template>
          </Steps>
        </div>
        <div class="row" v-if="step == 0">
          <div class="col-12 p-4">
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('name') }}</label>
            <InputText type="text" v-model="new_title" :placeholder="$t('incidentName')" style="width:100%;">
            </InputText>
            <label for="" class="pl-0 pb-0 mb-0 mt-1">{{ $t('description') }}</label>
            <Textarea type="text" v-model="new_desc" :placeholder="$t('alertDescription')" style="width:100%;">
              </Textarea>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('team') }}</label>
            <Dropdown :options="teams" optionLabel="name" v-model="new_team" :placeholder="$t('team')"
              style="width:100%;"></Dropdown>
            <label for="" class="pl-0 pb-0 mb-0">{{ $t('correlationRule') }}</label>
            <Dropdown :options="correlationRules" optionLabel="title" v-model="new_correlationRule"
              :placeholder="$t('correlationRule')" style="width:100%;"></Dropdown>
          </div>
        </div>
        <div class="row" v-if="step == 1">
          <div class="col-12 p-4">
            <DataTable :value="events" :paginator="true" class="p-datatable-customers" :rows="8" :row-hover="true"
              v-model:selection="new_selectedEvents" dataKey="id" v-model:filters="filters" filter-display="menu"
              :loading="loading_table" :global-filter-fields="['id', 'title']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="title" :header="$t('title')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.title }}
                  </span>
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="{ data }">
                  <span>
                    {{ data.description }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div class="row" v-if="step == 2">
          <div class="col-12 p-4">
            <DataTable :value="assets" :paginator="true" class="p-datatable-customers" :rows="8" :row-hover="true"
              v-model:selection="new_selectedAssets" dataKey="id" v-model:filters="filters" filter-display="menu"
              :loading="loading_table" :global-filter-fields="['id', 'name']" responsive-layout="scroll">
              <template #empty>
                {{ $t('noDataFound') }}
              </template>
              <template #loading>
                {{ $t('loadingData') }}
              </template>
              <Column selection-mode="multiple" header-style="width: 3rem"></Column>
              <Column field="name" :header="$t('name')" sortable>
                <template #body="{ data }">
                  <span class="row-name-link" style="cursor: default; text-decoration: none;">
                    {{ data.name }}
                  </span>
                </template>
              </Column>
              <Column field="description" :header="$t('description')" sortable>
                <template #body="{ data }">
                  <span>
                    {{ data.description }}
                  </span>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>

      </template>
      <template #footer>
        <Button v-if="step != 0" class="btn-custom-save" :label="$t('previous')" @click="step = step - 1"></Button>
        <Button v-if="step != 2" class="btn-custom-save" :label="$t('next')" @click="step = step + 1"></Button>
        <Button v-if="step == 2" class="btn-custom" :label="$t('save')"
          @click="addNewIncidents(new_title, new_desc)"></Button>
      </template>
    </Modal>
    <Modal ref="importData">
      <template #body>
        <div class="row">
          <div class="col-12 text-center pt-4">
            <i class="pi pi-th-large" style="font-size:2.5em;"></i>
          </div>
          <div class="col-12 text-center pt-0 pb-0">
            <h5 class="modal-title">{{ $t('import') }} {{ $t('assets') }}</h5>
            <h5 class="mt-3">
              <FileUpload mode="basic" name="demo[]" :auto="true" :chooseLabel="(uploadLoading)?$t('loading'):$t('import')" :disabled="uploadLoading" :maxFileSize="1000000"
                @uploader="onUploadVarious()" customUpload />
                
            </h5>
          </div>
        </div>
      </template>
      <template #footer>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style></style>
