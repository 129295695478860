<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
    <div class="adversary-card row">
        <div class="col-2 adversary-left-col">
            <img class="nav-bar-logo" v-if="campaign.picture" v-bind:src="getAdversarieLogo(campaign.picture)" alt="logo" />
            <img class="nav-bar-logo" v-else v-bind:src="adversaryTempImg" alt="logo" />
            <p class="adversary-img-caption">{{ $t('seenInYourCompany') }}</p>
            <div class="row text-center pl-2 pr-2">
                <div class="col-4 adversary-resume-icon">
                    <p v-tooltip.top="$t('seen_in_general')"><v-icon name="md-warning" scale="1.25" fill="#939393"></v-icon></p>
                    <p>{{7}}</p>
                </div>
                <div class="col-4 adversary-resume-icon">
                    <p v-tooltip.top="$t('seen_on_incidents')"><v-icon name="md-cases-round" scale="1.25" fill="#939393"></v-icon></p>
                    <p>{{3}}</p>
                </div>
                <div class="col-4 adversary-resume-icon">
                    <p v-tooltip.top="$t('seen_on_reports')"><v-icon name="md-description" scale="1.25" fill="#939393"></v-icon></p>
                    <p>{{4}}</p>
                </div>
            </div>
        </div>
        <div class="col-10 adversary-right-col pl-3">
            <h6 class="adversary-name" >{{campaign.name}} <span v-tooltip.top="$t('open_in_mitre')"><v-icon name="bi-box-arrow-up-right" scale="1" @click="openInMitre(campaign.mitre_url)" style="margin-bottom: 4px;cursor: pointer;"></v-icon></span></h6>
            <p class="adversary-description">{{wrapString(removeMarkdown(campaign.description),450)}}</p>
            <div class="row adversary-data w-100">
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('version')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{campaign.version}}</p>
                    <p class="adversary-data-title">{{$t('status')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{campaign.status}}</p>
                    <p class="adversary-data-title">{{$t('threat_level')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{campaign.threat_level}}</p>
                </div>
                <div class="col-4">
                    <p class="adversary-data-title">{{$t('first_seen')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{formatDate(campaign.first_seen)}}</p>
                    <p class="adversary-data-title">{{$t('last_seen')}}</p>
                    <hr class="m-0">
                    <p class="adversary-data-value">{{formatDate(campaign.last_seen)}}</p>
                </div>
                <div class="col-4">
                    
                </div>
            </div>
        </div>
    </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
export default {
    name: "AdversaryCard",
    data() {
        return {
            adversaryTempImg:  require('@/assets/user-default-img.png'),
        }
    },
    components: {
    },
    methods: {
        formatDate(date) {
            if (date == null || date == '') return;

            // Format date to dd/mm/yyyy hh:mm
            let d = new Date(date);
            let day = d.getDate();
            let month = d.getMonth() + 1;
            let year = d.getFullYear();
            let hour = d.getHours();
            let minutes = d.getMinutes();
            // Add 0 if month is less than 10
            if (month < 10) {
                month = "0" + month;
            }
            // Add 0 if day is less than 10
            if (day < 10) {
                day = "0" + day;
            }
            // Add 0 if minutes is less than 10
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            return `${day}/${month}/${year} ${hour}:${minutes}`;
        },
        getAdversarieLogo(logo) {
            return process.env.VUE_APP_BASE_URL + '/' + logo;
        },
        prettifyArray(array){
            if (array === null || array === undefined){
                return '';
            }
            return array.join(', ');
        },
        openInMitre(url){
            window.open(url, '_blank');
        },
        wrapString(string, max_char){
            if (string === null || string === undefined){
                return '';
            }
            if (string.length > max_char){
                return string.substring(0, max_char) + '...';
            }
            return string;
        },
        removeMarkdown(string){
            if (string === null || string === undefined)
                return '';
            // Find all url between () and remove them
            let a =  string.replace(/\(.*?\)/g, '');
            // Find [ remove them
            let b = a.replace(/\[/g, '');
            // Find ] remove them
            b = b.replace(/\]/g, '');
        
            return b;
        }
    },
    props: {
        campaign: Object,
    },
    mounted(){
        
    }
};
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>

</style>