<!----------------------------------------------->
<!--                  TEMPLATE                 -->
<!----------------------------------------------->
<template>
  <div class="aux">
    <Sitebar></Sitebar>
    <div class="maincontainer" style="padding-bottom:4em; padding-right: 50px;">
      <ConfirmDialog></ConfirmDialog>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Top ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div class="row create-dashboard-top mb-2">
        <div class="pt-0">
          <div class="row p-0 m-0">
            <h3 class="col-4 p-0 m-0">{{ dashboardName }}</h3>
            <div class="col-8 pr-0">
              <Button :label="$t('edit')" class="custom-btn" icon="pi pi-file-edit"
                @click="$router.push({ path: 'dashboard-creator', query: { id: $route.query.id } })"
                style="float:right" />
              <!--
                <Button :label="$t('createNewDashboard')" class="custom-btn mr-2" icon="pi pi-plus"
                @click="$router.push({ path: 'dashboard-creator' })" style="float:right" />
                -->
              <Button :label="$t('exportAsIframe')" class="custom-btn mr-2" icon="pi pi-stop" @click="exportAsIframe()"
                style="float:right" />
              <!--<Button :label="$t('createDashboardLink')" class="custom-btn mr-2" icon="pi pi-link" @click="createLink()" style="float:right" />-->
              <!--<Button :label="$t('exportAsPDF')" class="custom-btn mr-2" icon="pi pi-file-pdf" @click="exportAsPDF()" style="float:right" />-->
              <Button class="custom-btn mr-2 copy-input-btn" :icon="copied ? 'pi pi-check' : 'pi pi-copy'"
                @click="copyToClippboard()" style="float:right; height: 1.6rem;" />
              <InputText class="mr-0 copy-input-text" :placeholder="$t('clickToGenerate')" :value="link"
                style="float:right; width: 25rem;" disabled @click="copyToClippboard()"/>
            </div>
          </div>
        </div>
      </div>
      <!-- ==================================================================================================================================================== -->
      <!-- ======================================================================= Canvas ======================================================================= -->
      <!-- ==================================================================================================================================================== -->
      <div :class="maximized ? 'row maximized' : 'row minimized'" style="width: -moz-available;">
        <div class="col-12 pt-0 pb-0">
          <div class="dashboard-canvas-no-edit">
            <Button class="col-12 mr-2" :icon="maximized ? 'pi pi-window-minimize' : 'pi pi-window-maximize'"
              style="width:1.6rem !important; height: 2em !important; float:right; z-index: 99; background-color: black !important;"
              @click="maximized = !maximized" />
            <smart-widget-grid :layout="layout" :verticalCompact="false" @layout-updated="updateLayout" :colNum="18"
              :isStatic="true" ref="printToPDF" id="printToPDF">
              <!-- layout[$].i as slot name -->
              <template v-for="slot in layout" v-slot:[slot.i]="slotProps">
                <!-- ======================================================================= Visualization ======================================================================= -->
                <smart-widget simple :resized="/*refreshChart(slot.i)*/ null" style="height: inherit; width: auto;">
                  <div class="layout-center" :id="slot.i">
                    <h5 class="mb-0">{{ slot.name }}</h5>
                    <p class="mt-0 chart-desc">{{ slot.desc }}</p>
                    <!-- Loading auxs -->
                    <div class="loading-aux"
                      v-if="slot.typeof == 'line' || slot.typeof == 'bar' || slot.typeof == 'pie'">
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.chartData">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <div class="loading-aux" v-if="slot.typeof == 'number'">
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.number">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <div class="loading-aux" v-if="slot.typeof == 'text'">
                      <div class="loader text-center mt-2" style="width:100%;" v-if="!slot.text">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                        <p>{{ $t('loading') }}</p>
                      </div>
                    </div>
                    <!-- Chart -->
                    <Chart v-if="slot.typeof == 'line' && slot.chartData" type="line" :data="slot.chartData"
                      :options="lineChartOptions" />
                    <Chart v-if="slot.typeof == 'bar' && slot.chartData" type="bar" :data="slot.chartData"
                      :options="lineChartOptions" />
                    <Chart v-if="slot.typeof == 'pie' && slot.chartData" type="pie" :data="slot.chartData"
                      :options="pieChartOptions" />
                    <div class="text-chart" v-if="slot.typeof == 'text'">
                      <h6>{{ slot.text }}</h6>
                    </div>
                    <div class="number-chart" v-if="slot.typeof == 'number'">
                      <number style="font-size: 4em; font-weight:700;" ref="users_number" :from="0" :to="slot.number"
                        :duration="2" easing="Power1.easeOut" class="ml-2" :format="theFormat" />
                      <h3 class="ml-2" style="display:inline-block">{{ slot.unit }}</h3>
                    </div>
                  </div>
                  <div class="vue-resizable-handle"></div>
                </smart-widget>
                <!-- ==================================================================================================================================================== -->
              </template>
            </smart-widget-grid>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="iframeExport">
      <template #body>
        <h4 class="mt-2">{{ $t('hereIsYourIframeCode') }}</h4>
        <textarea class="form-control iframe-code" rows="3" v-model="iframeCode" readonly></textarea>
        <Button class="custom-btn mr-2" :icon="copied ? 'pi pi-check' : 'pi pi-copy'" @click="copyToClippboard()" style="float:right; height: 1.6rem;" :label="$t('copyToclipboard')"/>
      </template>
    </Modal>
  </div>
</template>
<!----------------------------------------------->
<!--                    SCRIPT                 -->
<!----------------------------------------------->

<script>
import MyShared from "./shared";
export default {
  mixins: [MyShared],
};

</script>

<script setup>
import { useMq } from "vue3-mq";
const mq = useMq();
</script>

<!----------------------------------------------->
<!--                    STYLES                 -->
<!----------------------------------------------->
<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.choose-chart-helper {
  font-size: 0.8em;
  color: #9e9e9e;
}

.chart-type {
  font-size: 1em;
  font-weight: 500;
  margin-bottom: 0;
}

.chart-desc {
  font-size: 0.8em;
  color: #9e9e9e;
  margin: 0;
  margin-bottom: 0.5em;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.chart-title {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.chart-type-selector {
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s;
}

.chart-type-selector:hover {
  background: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-canvas-no-edit {
  background: #ffffff;
  height: 100%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0.5em;
}

.vue-grid-item {
  border-radius: 5px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  background: #ffffff !important;
  z-index: 2 !important;
}

.vue-resizable-handle {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.vue-grid-item.vue-grid-placeholder {
  background: #eeeeee81 !important;
  z-index: 1 !important;
}

.maximized {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: white;
  margin: 0;
  overflow: auto;
}

.copy-input-text {
  border-radius: 5px 0 0 5px !important;
}

.copy-input-btn {
  border-radius: 0 5px 5px 0 !important;
}

.iframe-code{
  width: 100%;
  border-radius: 5px;
  font-size: 0.8em;
  font-family: 'Courier New', Courier, monospace;
  padding: 0.5em;
  margin: 0.5em;
  resize: none;
  background-color: #1b1b1b !important;
  color: #f5f5f5;
}
.iframe-code:focus{
  background-color: #1b1b1b !important;
  color: #f5f5f5;
}
</style>
