import Sitebar from "../../components/Partials/Sitebar/SiteBar.vue"
import Helper from "../../components/Partials/Helper/Helper.vue"
import Modal from "../../components/Partials/Modals/Modal.vue"
import ConfirmDialog from "primevue/confirmdialog";
import AdversaryCard from "../../components/Partials/AdversaryCard/AdversaryCard.vue";
import * as $api from "../../api/api";

import { ref } from 'vue';
import { useUserStore } from '../../store/store.js'

var MyShared = {
    /* Name */
    name: "Tasks",
    /* Data */
    setup() {

    },
    data() {
        return {
            loading: false,
            user: {
                "permissions": []
            },
            adversaries: [
                /*
                {
                    id: 1,
                    name: "Adversary 1",
                    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
                    version: "1.0.0",
                    alias: "Alias 1",
                    first_seen: "2021-09-01T00:00:00",
                    last_seen: "2021-09-01T00:00:00",
                    contributors: ["User 1", "User 2", "User 3"],
                    motivation: "Financial",
                    region: "Russia",
                    intel_reports: 5,
                }
                    */
            ],
        }
    },
    /* Components */
    components: {
        Sitebar,
        Helper,
        Modal,
        ConfirmDialog,
        AdversaryCard
    },
    /* Props */
    props: {},
    /* Methods */
    methods: {

    },
    async mounted() {
        this.loading = true;
        await $api.getAllAdversaries().then((response) => {
            this.adversaries = response;
            this.loading = false;
        }).catch((err) => {
            console.log(`[-] Error (getAllAdversaries): ${err}`);
        });
       
    }
};
export default MyShared;